import React, { useEffect, useState } from 'react';
import { DataTableBase, EmptyDatatable, DateFormatForSpecificField } from '../../uiComponents';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import download from '../../assets/images/download-document.svg';
import i18n from '../../i18n/config';
import { connect } from 'react-redux';
import { DataTableLoader } from '../Loader';
import { useAppDispatch } from '../../config/hook';
import { documentList } from '../../redux/action';

const columns = [
  {
    name: i18n.t('documentList.table.filename'),
    selector: (row) => row.fileName,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('documentList.table.documentCategory'),
    selector: (row) => row.documentCategory,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('documentList.table.createdOn'),
    selector: (row) => <DateFormatForSpecificField row={row} filedName={'createdOn'} />,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('documentList.table.download'),
    selector: (row) => <Download row={row} />,
    sortable: false,
    wrap: true,
    center: true,
  },
];

const columnsAssignmentDetails = [
  {
    name: i18n.t('documentList.table.filename'),
    selector: (row) => row.fileName,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('documentList.table.createdOn'),
    selector: (row) => <DateFormatForSpecificField row={row} filedName={'createdOn'} />,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('documentList.table.download'),
    selector: (row) => <Download row={row} />,
    sortable: false,
    wrap: true,
    center: true,
  },
];

function downloadPDF(documentString, name) {
  const linkSource = documentString;
  const downloadLink = document.createElement('a');
  const fileName = name;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const Download = ({ row }) => {
  const handleDownload = () => {
    downloadPDF(row.documentDownloadString, row.fileName);
  };
  return (
    <OverlayTrigger
      key={'left'}
      placement={'left'}
      overlay={
        <Tooltip id={`tooltip-left}`}>
          Download <strong>{row.fileName}</strong>.
        </Tooltip>
      }>
      <button className="btn" onClick={handleDownload}>
        <img src={download} height={'36px'} />
      </button>
    </OverlayTrigger>
  );
};

export const DocumentListTable = (props) => {
  const [list, setList] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props?.userId) {
      documentList(props?.userId, dispatch);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responsePayload = props?.response?.data?.documentList;
      setList(responsePayload ?? []);
    }
  }, [props.response]);

  useEffect(() => {
    if (props?.documentList?.length > 0) {
      setList(props?.documentList ?? []);
    }
  }, [props?.documentList]);

  return (
    <DataTableBase
      columns={props?.documentList ? columnsAssignmentDetails : columns}
      data={list}
      noDataComponent={<EmptyDatatable message={props.t('documentList.table.empty')} />}
      progressPending={props?.loading}
      progressComponent={<DataTableLoader />}
    />
  );
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.loading,
    response: common.documentList,
    error: common.error,
    errorResponse: common.errorResponse,
  };
}

export const DocumentListTableWrapper = connect(mapStateToProps)(DocumentListTable);
