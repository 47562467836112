import React from 'react';
import { DataTableBase, EmptyDatatable } from '../DataTable';
import '../../assets/css/data-table.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';

const assignmentTranscationCol = [
  {
    name: i18n.t('assignment.table.name'),
    selector: (row) => row.name,
  },
  {
    name: i18n.t('assignment.table.unitAmount'),
    selector: (row) => row.unitAmount,
  },
  {
    name: i18n.t('assignment.table.Quantity'),
    selector: (row) => row.quantity,
  },
  {
    name: i18n.t('assignment.table.sumAmount'),
    selector: (row) => row.sumAmount,
  },
];

const travelExpenseCol = [
  {
    name: i18n.t('assignment.table.travelAmount'),
    selector: (row) => row.amount,
    wrap: true,
  },
  {
    name: i18n.t('assignment.table.travelDescription'),
    selector: (row) => row.description,
    wrap: true,
  },
  {
    name: i18n.t('assignment.table.travelExpenseType'),
    selector: (row) => row.expenseType,
    wrap: true,
  },
];

export const TranscationTable = ({
  transaction,
  message =  `${i18n.t('assignment.table.noTranscation')}`,
  isTravel = false,
}) => {
  if (isTravel) {
    return (
      <DataTableBase
        columns={travelExpenseCol}
        data={transaction}
        noDataComponent={<EmptyDatatable message={message} />}
      />
    );
  }

  return (
    <DataTableBase
      columns={assignmentTranscationCol}
      data={transaction}
      noDataComponent={<EmptyDatatable message={message} />}
    />
  );
};
