import React, { useState, useEffect, useContext } from 'react';
import loginLogo from '../../assets/images/log-in.svg';
import { Loader } from '../Loader';
import { AuthContext } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { login } from '../../redux/action';
import { userTypeEnums } from '../../interface';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Login = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let loading = props.loading;
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (props?.response?.data) {
      if(props?.response?.status === 200){
        auth.setUpdatePassword(props?.response?.data?.isPasswordUpdateRequired ?? false);
        if (props.response.data.userType === userTypeEnums.INTERPRETER) {
          navigate('/publication');
        } else {
          navigate('/publication');
        }
      }
    }
  }, [props.response]);

  const resetError = () => {
    setError(false);
    setErrorMessage('');
  };

  const handleUserName = (e) => {
    setUserName(e.target.value);
    resetError();
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    resetError();
  };

  const onLogin = () => {
    if (userName !== '' || password !== '') {
      if (userName === '') {
        setError(true);
        setErrorMessage(t('login.errorUserName'));
      } else if (password === '') {
        setError(true);
        setErrorMessage(t('login.errorPassword'));
      } else {
        const requestPayload = { userName, password };
        login(requestPayload, dispatch);
      }
    } else {
      setError(true);
      setErrorMessage(t('login.emptyError'));
    }
  };

  return (
    <section className="bg-image">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10 col-xl-5 col-lg-5 col-md-6 col-sm-10">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="logoin_image mt-5">
                  <a href="/">
                    <img src={loginLogo} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="login_box text-start mt-5">
              <div style={{ textAlign: 'center' }}>{error && <p style={{ color: 'red' }}>{errorMessage}</p>}</div>
              <div className="mb-4">
                <label htmlFor="disabledSelect" className="form-label blue font_12">
                {t('login.userName')}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t('login.placeholderUserName')}
                  aria-label="default input example"
                  onChange={handleUserName}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="disabledSelect" className="form-label blue font_12">
                {t('login.password')}
                </label>
                <input
                  className="form-control"
                  type="password"
                  placeholder={t('login.placeholderPassword')}
                  aria-label="default input example"
                  onChange={handlePassword}
                />
              </div>
              <div className="place-order-hover">
                <button type="button" className="btn accept w-100" onClick={onLogin}>
                {t('login.loginButton')}
                </button>
              </div>
              <div className="text-center mt-3">
                <Link to="/forgot-password">
                  <span className="blue">{t('login.forgotPasswordLink')}</span>
                </Link>
              </div>
              {loading ? <Loader /> : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return {
    loading: user.loading,
    response: user.login,
    error: user.error,
    errorResponse: user.errorResponse,
  };
}

export const LoginWrapper = connect(mapStateToProps)(Login);
