import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Header, Footer } from '../components';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let navigate = useNavigate();
  let isLogged = localStorage.getItem('userSettings') ? true : false;
  if (isLogged) {
    var usertype = JSON.parse(localStorage.getItem('userSettings'))?.userType;
    var userId = JSON.parse(localStorage.getItem('userSettings'))?.userID;
  }

  useEffect(() => {
    if (rest?.auth?.updatePassword) {
      navigate('/update-password');
    }
  }, []);

  return isLogged ? (
    <>
      {rest?.noheader ? (
        <Component userType={usertype} userId={userId} />
      ) : (
        <>
          <Header /> <Component userType={usertype} userId={userId} /> <Footer />
        </>
      )}
    </>
  ) : (
    <Navigate to="/" />
  );
};
