import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { IModelProps } from '../../interface';

export const ModelPopup = (props: IModelProps) => {
  const { t } = useTranslation();
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <span className="place-order-hover">
          <button type="button" className="btn accept pe-4 ps-4" onClick={props.onHide}>
          {props.cancellabel}
          </button>
        </span>
        <span className="place-order-hover">
          <button type="button" className="btn accept pe-4 ps-4" onClick={props.onConfirmClick}>
            {props.confirmlabel}
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};
