import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  PROFILE_REQUEST,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_RESET,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS
} from '../reducer/userReducer';
import { postRequest, getRequest } from '../../utils';
import { successAlert, errorAlert } from '../../uiComponents';
import i18next from '../../i18n/config';

export async function login(requestPayload, dispatch) {
  dispatch(LOGIN_REQUEST());
  const login = await postRequest(`/Login`, requestPayload);
  if (login.data.status === 200) {
    //@ts-ignore
    dispatch(LOGIN_SUCCESS(login.data));
    localStorage.setItem('userSettings', JSON.stringify(login?.data?.data));
    //successAlert(login.data.message);
    //successAlert(i18next.t('login.successMessage'));
  } else {
    //@ts-ignore
    dispatch(LOGIN_ERROR(login.data));
    errorAlert(login.data.message);
  }
}

export async function getProfile(requestPayload: string, dispatch: any) {
  dispatch(PROFILE_REQUEST());
  const profile = await getRequest(`/profile?${requestPayload}`);
  if (profile.data.status === 200) {
    //@ts-ignore
    dispatch(PROFILE_SUCCESS(profile.data));
    //successAlert('Profilen ble hentet');
  } else {
    //@ts-ignore
    dispatch(PROFILE_ERROR(profile.data));
    errorAlert(profile.data.message);
  }
}


export const updatePassword = async (requestPayload, dispatch) => {
  try {
    dispatch(CHANGE_PASSWORD_REQUEST());
    const passwordUpdate = await postRequest(`/changepassword`, requestPayload);
    if (passwordUpdate.status === 200) {
      //@ts-ignore
      dispatch(CHANGE_PASSWORD_SUCCESS(passwordUpdate.data));
    } else {
      //@ts-ignore
      dispatch(CHANGE_PASSWORD_ERROR(passwordUpdate.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset password
export async function resetPassword(dispatch) {
  dispatch(CHANGE_PASSWORD_RESET());
}

export const forgotPassword = async (requestPayload, dispatch) => {
  try {
    dispatch(FORGOT_PASSWORD_REQUEST());
    const forgotPassword = await postRequest(`/forgotpassword/${requestPayload}`, null);
    if (forgotPassword.status === 200) {
      //@ts-ignore
      dispatch(FORGOT_PASSWORD_SUCCESS(forgotPassword.data));
    } else {
      //@ts-ignore
      dispatch(FORGOT_PASSWORD_ERROR(forgotPassword.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset forgot password
export async function resetForgotPassword(dispatch) {
  dispatch(FORGOT_PASSWORD_RESET());
}