import { combineReducers } from 'redux';
import { userSlice } from './userReducer';
import { assignmentSlice } from './assignmentReducer';
import { orderSlice } from './orderReducer';
import { commonSlice } from './commonReducer';
import { publicationSlice } from './publicationReducer';

export default combineReducers({
  user: userSlice.reducer,
  assignment: assignmentSlice.reducer,
  order: orderSlice.reducer,
  common: commonSlice.reducer,
  publication: publicationSlice.reducer,
});
