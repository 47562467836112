import React, { useContext, useEffect, useState } from 'react';
import { DataTableBase, EmptyDatatable } from '../../uiComponents';
import { connect } from 'react-redux';
import { RemoveFavouriteWrapper } from './RemoveFavourite';
import { useAppDispatch } from '../../config/hook';
import { DataTableLoader } from '../Loader';
import { GlobalContext } from '../../utils';
import { getFavouriteInterpreterList } from '../../redux/action';
import i18n from '../../i18n/config';
import './favourite.css';

const columns = [
  {
    name: i18n.t('favourite.table.name'),
    selector: (row) => row.intepreterFullName,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('favourite.table.email'),
    selector: (row) => row.emailAddress1,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('favourite.table.phoneNo'),
    selector: (row) => row.mobilePhone,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('favourite.table.remove'),
    selector: (row) => <RemoveFavouriteWrapper row={row} />,
    sortable: false,
    wrap: true,
    center: true,
  },
];

export const FavouriteDataTable = (props) => {
  const global = useContext(GlobalContext);
  const [favInterpreter, setFavInterpreter] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (global.relatedCustomer !== '') {
      let customerId = global.relatedCustomer === 'default' ? props?.id : global.relatedCustomer;
      getFavouriteInterpreterList(customerId, dispatch);
    }
  }, [global.relatedCustomer, global?.trigger]);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responsePayload = props?.response?.data;
      setFavInterpreter(responsePayload ?? []);
    }
  }, [props.response]);

  return (
    <DataTableBase
      columns={columns}
      data={favInterpreter}
      progressPending={props?.loading}
      progressComponent={<DataTableLoader />}
      noDataComponent={<EmptyDatatable message={props.t('favourite.table.empty')} />}
    />
  );
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.loading,
    response: common.response,
    error: common.error,
    errorResponse: common.errorResponse,
  };
}

export const FavouriteDataTableWrapper = connect(mapStateToProps)(FavouriteDataTable);
