import React, { useState } from 'react';
import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';
interface IAssigmentProps {
  assignment?: any;
  onClick?: (arg0: any, arg1: string) => void;
}

export const Request = (props: IAssigmentProps) => {
  const { t } = useTranslation();
  const [assignment] = useState(props?.assignment?.data);

  if (assignment === null)
    return (
      <div className="assign_p">
        <div className="assignment-message-info">
          <p className="font_18 blue">{props?.assignment?.message}</p>
        </div>
      </div>
    );

  return (
    <>
      <div className="assign_p">
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignmentRequest.assignmentNo')}</p>
          <span className="ms-auto">{assignment?.assignmentNumber}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignment.language')}</p>
          <span className="ms-auto">{assignment?.language}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignment.startTime')}</p>
          <span className="ms-auto">{formatDate(assignment?.startTime)}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignmentRequest.endTime')}</p>
          <span className="ms-auto">{formatDate(assignment?.endTime)}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignment.duration')}</p>
          <span className="ms-auto">{assignment?.durationInMinute}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignment.assignmentType')}</p>
          <span className="ms-auto">{assignment?.assignmentType}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
        <div className="d-md-flex mt-3 mb-3">
          <p className="font_18">{t('assignment.account')}</p>
          <span className="ms-auto">{assignment?.accountName}</span>
        </div>
        <div className="hr mt-3 mt-md-0"></div>
      </div>
      {assignment?.isValid === false && (
        <div className="row">
          <p className="font_18 blue" style={{ textAlign: 'center' }}>
            {props?.assignment?.message}
          </p>
        </div>
      )}
      {assignment?.isValid && (
        <div className="row mt-4 buttons">
          <div className="col-6">
            <button
              type="submit"
              className="btn accept w-100"
              onClick={(e) => {
                props.onClick(e, 'accept');
              }}>
              {t('assignmentRequest.accept')}
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              className="btn reject w-100"
              onClick={(e) => {
                props.onClick(e, 'reject');
              }}>
              {t('assignmentRequest.reject')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
