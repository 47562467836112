import { DOCUMENT_LIST_ERROR, DOCUMENT_LIST_REQUEST, DOCUMENT_LIST_SUCCESS } from '../reducer/commonReducer';
import { getRequest } from '../../utils';

export const documentList = async (requestPayload, dispatch) => {
  try {
    dispatch(DOCUMENT_LIST_REQUEST());
    const documents = await getRequest(`/interpreter/documentList?contactId=${requestPayload}`);
    if (documents.status === 200) {
      //@ts-ignore
      dispatch(DOCUMENT_LIST_SUCCESS(documents.data));
    } else {
      //@ts-ignore
      dispatch(DOCUMENT_LIST_ERROR(documents.data));
    }
  } catch (error) {
    console.error(error);
  }
};
