import React, { useState } from 'react';
import { GlobalContext } from './ContextStore';

export const GlobalProvider = (props) => {
  const [relatedCustomer, setRelatedCustomer] = useState<string>('');
  const [trigger, setTrigger] = useState<boolean>(false);
  const GlobalContextValues = {
    relatedCustomer,
    setRelatedCustomer,
    trigger,
    setTrigger
  };
  //@ts-ignore
  return <GlobalContext.Provider value={GlobalContextValues}>{props.children}</GlobalContext.Provider>;
};
