import { IReduxAction } from '../../interface';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: {},
  error: false,
  errorResponse: {},
  loading: false,
  lookupResponse: {},
  interpreterUploadDocumentResponse: {},
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    ORDER_LOOKUP_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ORDER_LOOKUP_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        lookupResponse: action.payload,
        loading: false,
        error: false,
      };
    },
    ORDER_LOOKUP_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    ORDER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ORDER_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      };
    },
    ORDER_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    RESET_ORDER: (state, action: IReduxAction) => {
      return {
        ...state,
        response: {},
        loading: false,
        error: false,
        interpreterUploadDocumentResponse: {},
      };
    },
    EDIT_ORDER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    EDIT_ORDER_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      };
    },
    EDIT_ORDER_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    INTERPRETER_DOCUMENT_UPLOAD_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    INTERPRETER_DOCUMENT_UPLOAD_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        loading: false,
        interpreterUploadDocumentResponse: action?.payload,
        error: false,
      };
    },
    INTERPRETER_DOCUMENT_UPLOAD_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        loading: false,
        error: true,
        errorResponse: action?.payload,
      };
    },
  },
});

export const {
  ORDER_LOOKUP_ERROR,
  ORDER_LOOKUP_SUCCESS,
  ORDER_LOOKUP_REQUEST,
  ORDER_ERROR,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  RESET_ORDER,
  EDIT_ORDER_ERROR,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  INTERPRETER_DOCUMENT_UPLOAD_ERROR,
  INTERPRETER_DOCUMENT_UPLOAD_REQUEST,
  INTERPRETER_DOCUMENT_UPLOAD_SUCCESS,
} = orderSlice.actions;
