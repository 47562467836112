import React, { useState } from 'react';
import { AuthContext } from './ContextStore';
import { IAuthContext } from '../../interface';
import Cookie from 'universal-cookie';

export const AuthProvider = (props) => {
  var cookie = new Cookie();
  const [updatePassword, setUpdatePassword] = useState(
    JSON.parse(localStorage.getItem('userSettings'))?.isPasswordUpdateRequired ?? false,
  );
  const setAuthCookies = (value: string) => {
    cookie.set('token', value, {
      sameSite: 'none',
      secure: true,
      maxAge: 30,
    });
  };

  const getAuthCookie = cookie.get('token');
  const removeAuthCookie = () => {
    cookie.remove('token');
  };

  const AuthContextValues: IAuthContext = {
    getAuthCookie,
    setAuthCookies,
    removeAuthCookie,
    updatePassword,
    //@ts-ignore
    setUpdatePassword,
  };
  //@ts-ignore
  return <AuthContext.Provider value={AuthContextValues}>{props.children}</AuthContext.Provider>;
};
