import React from 'react';
import DataTable, { TableProps } from 'react-data-table-component';

export const DataTableBase = <T,>(props: TableProps<T>): JSX.Element => {
  return <DataTable paginationRowsPerPageOptions={[10,20,25,50]} persistTableHead={true} {...props}/>;
};

export const paginationServerOption = {
  persistSelectedOnSort: false
}

export const defaultValues = {
  rowPerPages: 10,
  orderBy: 'STARTTIME',
  orderType: 'ASC',
  pageIndex: 1
}