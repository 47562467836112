import {
  ASSIGNMENT_REQUEST,
  ASSIGNMENT_SUCCESS,
  ASSIGNMENT_ERROR,
  ACCEPT_ASSIGNMENT_ERROR,
  ACCEPT_ASSIGNMENT_REQUEST,
  ACCEPT_ASSIGNMENT_SUCCESS,
  REJECT_ASSIGNMENT_ERROR,
  REJECT_ASSIGNMENT_REQUEST,
  REJECT_ASSIGNMENT_SUCCESS,
  ASSIGNMENT_LIST_ERROR,
  ASSIGNMENT_LIST_REQUEST,
  ASSIGNMENT_LIST_SUCCESS,
  ASSIGNMENT_DETAIL_REQUEST,
  ASSIGNMENT_DETAIL_SUCCESS,
  ASSIGNMENT_DETAIL_ERROR,
  INTERPRETER_LIST_ERROR,
  INTERPRETER_LIST_REQUEST,
  INTERPRETER_LIST_SUCCESS,
  DOWNLOAD_ASSIGNMENT_PDF_ERROR,
  DOWNLOAD_ASSIGNMENT_PDF_REQUEST,
  DOWNLOAD_ASSIGNMENT_PDF_SUCCESS,
  DOWNLOAD_ASSIGNMENT_PDF_RESET,
  ASSIGNMENT_RESET,
  CANCEL_ASSIGNMENT_ERROR,
  CANCEL_ASSIGNMENT_REQUEST,
  CANCEL_ASSIGNMENT_SUCCESS,
  REPORT_ERROR,
  REPORT_REQUEST,
  REPORT_SUCCESS,
  STATUSREASON_LOOKUP_ERROR,
  STATUSREASON_LOOKUP_REQUEST,
  STATUSREASON_LOOKUP_SUCCESS,
  FILTER_HISTORY,
  EXPORT_TO_EXCEL_ERROR,
  EXPORT_TO_EXCEL_REQUEST,
  EXPORT_TO_EXCEL_SUCCESS,
  EXPORT_TO_EXCEL_RESET,
  DOCUMENT_LOOKUP_ERROR,
  DOCUMENT_LOOKUP_REQUEST,
  DOCUMENT_LOOKUP_SUCCESS
} from '../reducer/assignmentReducer';
import { getRequest, postRequest } from '../../utils';
import { successAlert, errorAlert } from '../../uiComponents';

export async function getAssignment(requestPayload, dispatch) {
  dispatch(ASSIGNMENT_REQUEST());
  const fetchAssignment = await getRequest(`/Assignment/${requestPayload}`);
  if (fetchAssignment.data.status === 200) {
    //@ts-ignore
    dispatch(ASSIGNMENT_SUCCESS(fetchAssignment.data));
  } else {
    //@ts-ignore
    dispatch(ASSIGNMENT_ERROR(fetchAssignment.data));
  }
}

export async function acceptAssignment(postPayload, dispatch) {
  dispatch(ACCEPT_ASSIGNMENT_REQUEST());
  const acceptAssignment = await postRequest(`Assignment/Accept/${postPayload}`, null);
  if (acceptAssignment.data.status === 200) {
    //@ts-ignore
    dispatch(ACCEPT_ASSIGNMENT_SUCCESS(acceptAssignment.data));
    successAlert(acceptAssignment.data.message);
  } else {
    //@ts-ignore
    dispatch(ACCEPT_ASSIGNMENT_ERROR(acceptAssignment.data));
    errorAlert(acceptAssignment.data.message);
  }
}

export async function rejectAssignment(postPayload, dispatch) {
  dispatch(REJECT_ASSIGNMENT_REQUEST());
  const rejectAssignment = await postRequest(`Assignment/Reject/${postPayload}`, null);
  if (rejectAssignment.data.status === 200) {
    //@ts-ignore
    dispatch(REJECT_ASSIGNMENT_SUCCESS(rejectAssignment.data));
    successAlert(rejectAssignment.data.message);
  } else {
    //@ts-ignore
    dispatch(REJECT_ASSIGNMENT_ERROR(rejectAssignment.data));
    errorAlert(rejectAssignment.data.message);
  }
}

export async function assignmentListCustomer(requestPayload, dispatch) {
  dispatch(ASSIGNMENT_LIST_REQUEST());
  const assignmentCustomerList = await postRequest(`/customer/assignment/list`, requestPayload);
  //@ts-ignore
  dispatch(FILTER_HISTORY(requestPayload));
  if (assignmentCustomerList.data.status === 200) {
    //@ts-ignore
    dispatch(ASSIGNMENT_LIST_SUCCESS(assignmentCustomerList.data));
  } else {
    //@ts-ignore
    dispatch(ASSIGNMENT_LIST_ERROR(assignmentCustomerList.data));
  }
}

export async function getAssignmentDetails(requestPayload, dispatch) {
  dispatch(ASSIGNMENT_DETAIL_REQUEST());
  const assignmentDetails = await getRequest(
    `/Assignment/Details/View?assignmentNo=${requestPayload.assignmentNo}&contactType=${requestPayload.contactType}`,
  );
  if (assignmentDetails.data.status === 200) {
    //@ts-ignore
    dispatch(ASSIGNMENT_DETAIL_SUCCESS(assignmentDetails.data));
  } else {
    //@ts-ignore
    dispatch(ASSIGNMENT_DETAIL_ERROR(assignmentDetails.data));
  }
}

export async function assignmentListInterpreter(requestPayload, dispatch) {
  dispatch(INTERPRETER_LIST_REQUEST());
  const assignmentInterpreterList = await postRequest(`/interpreter/assignment/list`, requestPayload);
  //@ts-ignore
  dispatch(FILTER_HISTORY(requestPayload));
  if (assignmentInterpreterList.data.status === 200) {
    //@ts-ignore
    dispatch(INTERPRETER_LIST_SUCCESS(assignmentInterpreterList.data));
  } else {
    //@ts-ignore
    dispatch(INTERPRETER_LIST_ERROR(assignmentInterpreterList.data));
  }
}

export async function downloadAssignmentPDF(requestPayload, dispatch) {
  dispatch(DOWNLOAD_ASSIGNMENT_PDF_REQUEST());
  const downloadPDF = await getRequest(`/Assignment/GetDocument?fileUrl=${requestPayload}`);
  if (downloadPDF.data.status === 200) {
    //@ts-ignore
    dispatch(DOWNLOAD_ASSIGNMENT_PDF_SUCCESS(downloadPDF.data));
  } else {
    //@ts-ignore
    dispatch(DOWNLOAD_ASSIGNMENT_PDF_ERROR(downloadPDF.data));
  }
}

//Reset download pdf
export async function resetDownloadPdf(dispatch) {
  dispatch(DOWNLOAD_ASSIGNMENT_PDF_RESET());
}

//assignment cancel
export async function cancelAssignment(requestPayload, dispatch) {
  try {
    dispatch(CANCEL_ASSIGNMENT_REQUEST());
    const cancelAssignment = await postRequest(`/customer/cancelOrder/${requestPayload}`, null);
    if (cancelAssignment.data.status === 200) {
      //@ts-ignore
      dispatch(CANCEL_ASSIGNMENT_SUCCESS(cancelAssignment.data));
    } else {
      //@ts-ignore
      dispatch(CANCEL_ASSIGNMENT_ERROR(cancelAssignment.data));
    }
  } catch (error) {
    console.error(error);
  }
}

//Reset Assignment state
export async function resetAssignment(dispatch) {
  dispatch(ASSIGNMENT_RESET());
}

//Report Assignment
export async function reportAssignment(requestPayload, dispatch) {
  try {
    dispatch(REPORT_REQUEST());
    const report = await postRequest(`/interpreter/reportassignment`, requestPayload);
    if (report.data.status === 200) {
      //@ts-ignore
      dispatch(REPORT_SUCCESS(report.data));
    } else {
      //@ts-ignore
      dispatch(REPORT_ERROR(report.data));
    }
  } catch (error) {
    console.error(error);
  }
}

//Status reason lookup
export const getStatusReasonlookup = async (requestPayload, dispatch) => {
  try {
    dispatch(STATUSREASON_LOOKUP_REQUEST());
    const lookupStatus = await getRequest(`/lookup/getallstatusreason`);
    if (lookupStatus.data.status === 200) {
      //@ts-ignore
      dispatch(STATUSREASON_LOOKUP_SUCCESS(lookupStatus.data));
    } else {
      //@ts-ignore
      dispatch(STATUSREASON_LOOKUP_ERROR(lookupStatus.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Export to Excel
export const exportToExcel = async (requestPayload, dispatch, userType) => {
  try {
    dispatch(EXPORT_TO_EXCEL_REQUEST());
    const exportExcel = await postRequest(`/${userType}/assignment/list`, requestPayload);
    if (exportExcel.data.status === 200) {
      //@ts-ignore
      dispatch(EXPORT_TO_EXCEL_SUCCESS(exportExcel.data));
    } else {
      //@ts-ignore
      dispatch(EXPORT_TO_EXCEL_ERROR(exportExcel.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Status reason lookup
export const getDocumentlookup = async (dispatch) => {
  try {
    dispatch(DOCUMENT_LOOKUP_REQUEST());
    const lookupStatus = await getRequest(`/lookup/documenttypes`);
    if (lookupStatus.data.status === 200) {
      //@ts-ignore
      dispatch(DOCUMENT_LOOKUP_SUCCESS(lookupStatus.data));
    } else {
      //@ts-ignore
      dispatch(DOCUMENT_LOOKUP_ERROR(lookupStatus.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset Excel export
export const resetExcelExport = async (dispatch) => {
  dispatch(EXPORT_TO_EXCEL_RESET());
};
