import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { DataTableBase, EmptyDatatable, DateFormat, paginationServerOption, defaultValues } from '../../uiComponents';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { Link } from 'react-router-dom';
import { DataTableLoader } from '../Loader';
import { getSearchPayload, GlobalContext, formatDate } from '../../utils';
import { assignmentListInterpreter } from '../../redux/action';
import { sortFieldValueEnums, sortTypeEnum } from '../../interface';
import i18n from '../../i18n/config';
import './interpreter-home.css';

const CustomAssignmentNo = ({ row }) => {
  return (
    <Link to={`/view/${row.assignmentNo}`} state={`${formatDate(row.startTime)} ${row.account}`}>
      <label className={classNames('link', { t_underline: !row?.isCancelled, t_underline_red: row?.isCancelled })}>
        {row.assignmentNo}
      </label>
    </Link>
  );
};
const columns = [
  {
    name: i18n.t('dashboard.table.assignmentNo'),
    selector: (row) => <CustomAssignmentNo row={row} />,
    sortable: true,
    sortField: 'assignmentNo',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.account'),
    selector: (row) => row.account,
    sortable: true,
    sortField: 'account',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.startTime'),
    selector: (row) => <DateFormat row={row} />,
    sortable: true,
    sortField: 'startTime',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.assignmentType'),
    selector: (row) => row.assignmentType,
    sortable: true,
    sortField: 'assignmentType',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.orderByContactName'),
    selector: (row) => row.orderByContactName,
    sortable: true,
    sortField: 'orderByContactName',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.duration'),
    selector: (row) => row.duration,
    sortable: false,
    sortField: 'duration',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.language'),
    selector: (row) => row.language,
    sortable: true,
    sortField: 'language',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.interpreterSum'),
    selector: (row) => row.sumPayout,
    sortable: true,
    sortField: 'sumPayout',
    wrap: true,
  },
  {
    name: i18n.t('dashboard.table.status'),
    selector: (row) => row.status,
    sortable: true,
    sortField: 'status',
    wrap: true,
  },
];

const conditionalStyleRow = [
  {
    when: (row) => row?.isCancelled === true,
    classNames: ['cancelled-row'],
  },
];

export const InterpreterDataTable = (props: any) => {
  const global = useContext(GlobalContext);
  const dispatch = useAppDispatch();
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultValues.rowPerPages);
  const [totalRows, setTotalRows] = useState(0);
  const [orderBy, setOrderBy] = useState<string>(defaultValues.orderBy);
  const [orderType, setOrderType] = useState<string>(defaultValues.orderType);
  const [assignmentList, setAssignmentList] = useState<[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(defaultValues.pageIndex);

  useEffect(() => {
    const searchPayload = getSearchPayload(
      props.userType,
      props?.id,
      props?.assignmentStatus,
      '',
      '',
      pageIndex,
      rowsPerPage,
      orderBy,
      orderType,
      props?.commonFilter?.statusReason,
      props?.commonFilter?.fromDate,
      props?.commonFilter?.toDate,
    );
    assignmentListInterpreter(searchPayload, dispatch);
  }, [props?.assignmentStatus, rowsPerPage, orderBy, pageIndex, orderType, props?.commonFilter]);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responsePayload = props?.response?.data;
      setAssignmentList(responsePayload?.assignment ?? []);
      setTotalRows(responsePayload?.totalCount);
    }
  }, [props.response]);

  const onChangeRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setPageIndex(1);
  };

  const handlePageChange = (pageIndex) => {
    setPageIndex(pageIndex);
  };

  const handleSort = (column, sortDirection) => {
    setOrderBy(sortFieldValueEnums[column.sortField]);
    setOrderType(sortTypeEnum[sortDirection]);
  };

  return (
    <DataTableBase
      columns={columns}
      data={assignmentList}
      pagination
      paginationServer
      paginationDefaultPage={pageIndex}
      paginationPerPage={rowsPerPage}
      paginationTotalRows={totalRows}
      paginationServerOptions={paginationServerOption}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={handlePageChange}
      sortServer
      onSort={handleSort}
      progressPending={props?.loading}
      progressComponent={<DataTableLoader />}
      defaultSortFieldId={3}
      noDataComponent={<EmptyDatatable message={props.t('dashboard.empty')} />}
      conditionalRowStyles={conditionalStyleRow}
    />
  );
};

function mapStateToProps(state) {
  const { assignment } = state;
  return {
    loading: assignment.loading,
    response: assignment.assignmentList,
    error: assignment.error,
    errorResponse: assignment.errorResponse,
  };
}

export const InterpreterDataTableWrapper = connect(mapStateToProps)(InterpreterDataTable);
