import React, { useState, useEffect } from 'react';
import { SubHeader } from '../../uiComponents';
import { useTranslation } from 'react-i18next';
import { PublicationCard } from './PublicationCard';
import { getPublication } from '../../redux/action';
import { userTypeEnums } from '../../interface';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { Loader } from '../Loader';
import { errorAlert } from '../../uiComponents';
import './publication.css';

export const Publication = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const leftContent = {
    title: t('publication.title'),
    breadcrumb: '',
  };
  const [publication, setPublication] = useState<[]>([]);

  useEffect(() => {
    const requestPayload = {
      isPublishedForCustomer: props?.userType === userTypeEnums.CUSTOMER ? true : false,
      isPublishedForInterpreter: props?.userType === userTypeEnums.INTERPRETER ? true : false,
    };
    getPublication(requestPayload, dispatch);
  }, []);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responseValue = props?.response;
      if (responseValue.status === 200) {
        setPublication(responseValue.data);
      } else {
        errorAlert(responseValue.message);
      }
    }
  }, [props.response]);

  return (
    <div className="bg_blue p-3 content">
      <div className="mt-2 pb-2">
        <SubHeader isDisplay={false} leftContent={leftContent} />
        {props.loading ? <Loader /> : <PublicationCard publication={publication} t={t} />}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { publication } = state;
  return {
    loading: publication.loading,
    response: publication.response,
    error: publication.error,
    errorResponse: publication.errorResponse,
  };
}

export const PublicationWrapper = connect(mapStateToProps)(Publication);
