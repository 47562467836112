import React from 'react';
import { formatDate } from '../../utils';

export const DateFormat = ({ row }) => {
  return <label>{formatDate(row.startTime)}</label>;
};

export const DateFormatForSpecificField = ({row, filedName}) => {
  return <label>{formatDate(row[filedName])}</label>;
};
