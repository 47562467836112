import { utils, writeFileXLSX } from 'xlsx';
import { exportExcelFileName, exportExcelSheetName } from './constantValues';

export const exportToExcelAndDownload = async (payload) => {
  let wb = utils.book_new();
  let ws = utils.json_to_sheet(payload);

  utils.book_append_sheet(wb, ws, exportExcelSheetName);

  writeFileXLSX(wb, exportExcelFileName);
};
