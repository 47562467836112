import React, { useState, useEffect, useContext } from 'react';
import loginLogo from '../../assets/images/log-in.svg';
import { updatePassword, resetPassword } from '../../redux/action';
import { userTypeEnums } from '../../interface';
import { Loader } from '../Loader';
import { AuthContext } from '../../utils';
import { errorAlert, successAlert } from '../../uiComponents';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../config/hook';
import Form from 'react-bootstrap/Form';
import './change-password.css';

export const ChangePassword = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  let loading = props.loading;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [noMatch, setNoMatch] = useState(false);

  useEffect(() => {
    if (auth.updatePassword === false) {
      if (props?.userType === userTypeEnums.INTERPRETER) {
        navigate('/interpreter/dashboard');
      } else {
        navigate('/customer/dashboard');
      }
    }
    return () => {
      resetPassword(dispatch);
    };
  }, []);

  useEffect(() => {
    if (props?.response?.status) {
      if (props?.response?.status === 200) {
        successAlert(`${t('updatePassword.successMessage')}`);
        navigate('/');
      } else {
        errorAlert(props?.errorResponse?.message);
      }
    }
  }, [props.response]);

  const onchangePassword = (e: any) => {
    setPassword(e.target.value);
    setErrorPassword(false);
  };

  const onchangeConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
    setNoMatch(false);
  };

  const handleUpdatePassword = () => {
    let passwordRegx = new RegExp('^(?=.{8,})');
    if (passwordRegx.test(password) === false) {
      setErrorPassword(true);
      return;
    }
    if (password !== confirmPassword) {
      setNoMatch(true);
      return;
    }

    if (passwordRegx.test(password) && password === confirmPassword) {
      const requestPayload = {
        id: props?.userId,
        newPassword: password,
      };
      updatePassword(requestPayload, dispatch);
    }
  };

  return (
    <section className="bg-image">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10 col-xl-5 col-lg-5 col-md-6 col-sm-10">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="logoin_image mt-5">
                  <a href="/">
                    <img src={loginLogo} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="login_box text-start mt-5">
              <div className="mb-4">
                <label className="form-label blue font_12">{t('updatePassword.newPassword')}</label>
                <Form.Control
                  type="password"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  onChange={onchangePassword}
                />
                <Form.Text id="passwordHelpBlock" muted className={errorPassword ? 'error' : null}>
                  {t('updatePassword.passwordMessage')}
                </Form.Text>
              </div>
              <div className="mb-4">
                <label className="form-label blue font_12">{t('updatePassword.confirmPassword')}</label>
                <Form.Control
                  type="password"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  onChange={onchangeConfirmPassword}
                />
                <Form.Text id="passwordHelpBlock" muted className={noMatch ? 'error' : null}>
                  {t('updatePassword.confirmPasswordMessage')}
                </Form.Text>
              </div>
              <div className="place-order-hover">
                <button type="button" className="btn accept w-100" onClick={handleUpdatePassword}>
                {t('updatePassword.updateButton')}
                </button>
              </div>
            </div>
          </div>
          {loading ? <Loader /> : null}
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return {
    loading: user.loading,
    response: user.changePassword,
    error: user.error,
    errorResponse: user.errorResponse,
  };
}

export const ChangePasswordWrapper = connect(mapStateToProps)(ChangePassword);
