import React, { useState, useEffect } from 'react';
import { TranscationTable, DateTimeInput } from '../../uiComponents';
import { formatDate, checkEndDateWithCurrent, DateFormats, fromStringToDate, fromDateToString } from '../../utils';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-datepicker/dist/react-datepicker.css';
import { IReport, travelTypeEnum } from '../../interface';

export const Travel = ({ travel, onClick }) => {
  const { t } = useTranslation();
  const [isAssignmentEnd, setIsAssignmentEnd] = useState<boolean>(false);
  const [actualStartDate, setActualStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [actualEndDate, setActualEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [km, setKm] = useState('');
  const [travelTime, setTravelTime] = useState('');
  const [travelTimeIsInValid, setTravelTimeIsInValid] = useState<boolean>(false);

  const handleIsReport = async () => {
    const report = checkEndDateWithCurrent(travel?.endTime);
    if (report) {
      setIsAssignmentEnd(true);
      await setStartEndInActual();
    }
  };

  const handleDatePickerInitial = (value: string) => {
    return fromStringToDate(value, DateFormats.APIFORMAT).toDate();
  };

  const setStartEndInActual = async () => {
    if (travel?.startTime !== '' && travel?.endTime !== '') {
      setActualStartDate(handleDatePickerInitial(travel?.startTime));
      setActualEndDate(handleDatePickerInitial(travel?.endTime));
      setStartTime(handleDatePickerInitial(travel?.startTime));
      setEndTime(handleDatePickerInitial(travel?.endTime));
    }
  };

  useEffect(() => {
    if (travel?.endTime !== '') {
      handleIsReport();
    }
    if (travel?.actualStartTime !== '' && travel && travel?.actualStartTime !== undefined) {
      setActualStartDate(handleDatePickerInitial(travel?.actualStartTime));
      setActualEndDate(handleDatePickerInitial(travel?.actualEndTime));
      setStartTime(handleDatePickerInitial(travel?.actualStartTime));
      setEndTime(handleDatePickerInitial(travel?.actualEndTime));
    }
  }, [travel.endTime]);

  const onKmChange = (e) => {
    setKm(e.target.value);
  };

  const onTravelChange = (e) => {
    const pattern = /^([1-9]\d*)$/i;
    if (pattern.test(e.target.value) && e.target.value > 5) {
      setTravelTime(e.target.value);
      setTravelTimeIsInValid(false);
    } else {
      setTravelTimeIsInValid(true);
    }
  };

  const handleReportClick = () => {
    const requestPayload: IReport = {
      assignmentId: travel?.assignmentId,
      actualStartTime: `${fromDateToString(actualStartDate, DateFormats.DYNAMICSEARCHFORMAT)} ${fromDateToString(
        startTime,
        DateFormats.TIME,
      )}`,
      actualEndTime: `${fromDateToString(actualEndDate, DateFormats.DYNAMICSEARCHFORMAT)} ${fromDateToString(
        endTime,
        DateFormats.TIME,
      )}`,
      endTime:`${travel?.endTime}`,
      km: km === '' ? travel?.kilometers : km,
      traveltime: travelTime === '' ? travel?.travelTime : travelTime,
    };
    onClick(requestPayload);
  };

  const handleStartDate = (date) => {
    setActualStartDate(date);
  };

  const handleStartTime = (date) => {
    setStartTime(date);
  };

  const handleEndDate = (date) => {
    setActualEndDate(date);
  };

  const handleEndTime = (date) => {
    setEndTime(date);
  };

  const allowOnlyNumber = (e) => {
    if (e.keyCode === 8) {
      return;
    }
    return !/[0-9]/.test(e.key) && e.preventDefault();
  };

  return (
    <div className="bg-white p-4 mt-5 mt-lg-0">
      <div className="interpreter_infor">
        <div className="interpreter_infor_heading">
          {/* <p className="font_18 font_700 blue">{t('assignment.travelBase.travelDetails')}</p> */}
          <Stack direction="horizontal" gap={3}>
            <p className="font_18 font_700 blue me-auto" style={{ marginBottom: '0px' }}>
              {t('assignment.travelBase.travelDetails')}
            </p>
            {isAssignmentEnd &&
              (travel?.reportedByInterpretertime === '' ? (
                <Badge pill className="badge-custom me-auto">
                  {t('assignment.interpreterEdit.reportMessage')}
                </Badge>
              ) : (
                <Badge pill className="badge-custom-report" bg="success">
                  {t('assignment.interpreterEdit.alreadyreportMessage')}
                </Badge>
              ))}

            {isAssignmentEnd && travel?.reportedByInterpretertime === '' && (
              <span className="place-order-hover me-3">
                <button
                  type="button"
                  className="btn accept pe-4 ps-4"
                  onClick={handleReportClick}
                  disabled={travelTimeIsInValid}>
                  {t('assignment.interpreterEdit.reportButton')}
                </button>
              </span>
            )}
          </Stack>
        </div>
        <div className="row">
          <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="assignment_info mt-3">
              <p className="light_blue">{t('assignment.travelBase.fixedCost')}</p>
              <p className="blue font_18">
                {travel?.typeOfTravelCompensation !== travelTypeEnum.VARIABLE ? travel?.fixedTravelCost : 'No'}
              </p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.travelBase.Kilometers')}</p>
              {travel?.typeOfTravelCompensation === travelTypeEnum.VARIABLE &&
              isAssignmentEnd &&
              travel?.reportedByInterpretertime === '' ? (
                <input
                  className="form-control"
                  type="text"
                  placeholder={t('assignment.travelBase.Kilometers')}
                  defaultValue={travel?.kilometers}
                  onChange={onKmChange}
                />
              ) : (
                <p className="blue font_18">{travel?.kilometers}</p>
              )}
            </div>
          </div>
          <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 pb-4">
            <div className="assignment_info mt-3">
              <p className="light_blue">{t('assignment.travelBase.traveltime')}</p>
              {travel?.typeOfTravelCompensation === travelTypeEnum.VARIABLE &&
              isAssignmentEnd &&
              travel?.reportedByInterpretertime === '' ? (
                <InputGroup>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder={t('assignment.travelBase.traveltime')}
                    defaultValue={travel?.travelTime}
                    onChange={onTravelChange}
                    isInvalid={travelTimeIsInValid}
                    onKeyDown={allowOnlyNumber}
                  />
                  <InputGroup.Text id="basic-addon2">minutter</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {t('assignment.travelBase.travelTimeError')}
                  </Form.Control.Feedback>
                </InputGroup>
              ) : (
                <p className="blue font_18">{travel?.travelTime}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="interpreter_infor">
          <div className="row">
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 pb-5">
              <div className="assignment_info">
                <p className="light_blue">{t('assignment.actualStart')}</p>
                {isAssignmentEnd && travel?.reportedByInterpretertime === '' ? (
                  <DateTimeInput
                    selected={actualStartDate}
                    onChangeDate={handleStartDate}
                    datePickerClass="form-control date-time"
                    dateFormat="dd.MM.yyyy"
                    selectedTime={startTime}
                    onChangeTime={handleStartTime}
                    datePickerClassTime="form-control date-time"
                    dateFormatTime="HH:mm"
                  />
                ) : (
                  <p className="blue font_18">{travel?.actualStartTime ? formatDate(travel?.actualStartTime) : ''}</p>
                )}
              </div>
            </div>
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="assignment_info">
                <p className="light_blue">{t('assignment.actualEnd')}</p>
                {isAssignmentEnd && travel?.reportedByInterpretertime === '' ? (
                  <DateTimeInput
                    selected={actualEndDate}
                    onChangeDate={handleEndDate}
                    datePickerClass="form-control date-time"
                    dateFormat="dd.MM.yyyy"
                    selectedTime={endTime}
                    onChangeTime={handleEndTime}
                    datePickerClassTime="form-control date-time"
                    dateFormatTime="HH:mm"
                  />
                ) : (
                  <p className="blue font_18">{travel?.actualEndTime ? formatDate(travel?.actualEndTime) : ''}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="interpreter_infor">
            <div className="interpreter_infor_heading">
              <p className="font_18 font_700 blue">{t('assignment.table.travelExpences')}</p>
            </div>
          </div>
          <TranscationTable
            transaction={travel?.travelExpenses ?? []}
            message={t('assignment.table.noTravel')}
            isTravel
          />
        </div>
      </div>
    </div>
  );
};
