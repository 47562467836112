import React from 'react';
import { useTranslation } from 'react-i18next';
import './notfound.css';

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>{t('notFound.header')}</h2>
        <p>{t('notFound.pageNotFound')}</p>
        <a href="/">{t('login.backToLogin')}</a>
      </div>
    </div>
  );
};
