import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { IWeekDays } from '../../interface';
import { useAppDispatch } from '../../config/hook';
import { DataTableLoader } from '../Loader';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import i18n from '../../i18n/config';
import { getUnavailableLookup, addUnavailability } from '../../redux/action';
import { fromDateToString, DateFormats } from '../../utils';

const weekDaysList = [
  `${i18n.t('unAvailability.add.weekDays.monday')}`,
  `${i18n.t('unAvailability.add.weekDays.tuesday')}`,
  `${i18n.t('unAvailability.add.weekDays.wednesday')}`,
  `${i18n.t('unAvailability.add.weekDays.thursday')}`,
  `${i18n.t('unAvailability.add.weekDays.friday')}`,
  `${i18n.t('unAvailability.add.weekDays.saturday')}`,
  `${i18n.t('unAvailability.add.weekDays.sunday')}`,
];

export const AddUnavailability = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [title, setTitle] = useState('');
  const [frequencyLookup, setFrequencyLookup] = useState([]);
  const dispatch = useAppDispatch();
  const [weekDays, setWeekDays] = useState<IWeekDays>({
    weekDays: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
  });

  const { t } = useTranslation();

  useEffect(() => {
    getUnavailableLookup(null, dispatch);
  }, []);

  useEffect(() => {
    if (props.isAddClick) {
      const requestPayload = {
        interpreterId: props?.userId,
        name: title,
        frequency: 206500000,
        fromDate: fromDateToString(startDate, DateFormats.DYNAMICSEND24FORMAT),
        toDate: fromDateToString(endDate, DateFormats.DYNAMICSEND24FORMAT),
        ...weekDays,
      };
      addUnavailability(requestPayload, dispatch);
    }
  }, [props.isAddClick]);

  useEffect(() => {
    if (Object.keys(props?.lookup).length > 0) {
      const responsePayload = props?.lookup;
      if (responsePayload.status === 200) {
        setFrequencyLookup(responsePayload.data ?? []);
      }
    }
  }, [props.lookup]);

  useEffect(() => {
    if (Object.keys(props?.addResponse).length > 0) {
      const responsePayload = props?.addResponse;
      if (responsePayload.status === 200) {
        props.onCustomHide();
        window.location.reload();
      }
    }
  }, [props.addResponse]);

  const handleOnTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const handleWeekDays = (key) => {
    let mykey = key.toLowerCase();
    let oldWeekdays = weekDays.weekDays;
    let update = {
      ...oldWeekdays,
      [mykey]: !oldWeekdays[mykey],
    };
    setWeekDays({ weekDays: update });
  };

  if (props.loading) return <DataTableLoader />;

  return (
    <Form>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2" className="form-label blue font_12">
          {t('unAvailability.table.name')}
        </Form.Label>
        <Col sm="10">
          <Form.Control type="text" placeholder={t('unAvailability.table.name')} onChange={handleOnTitle} />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2" className="form-label blue font_12">
          {t('unAvailability.table.start')}
        </Form.Label>
        <Col sm="10">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setEndDate(date);
            }}
            className={'form-control date-time blue'}
            timeInputLabel="Time:"
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeInput
            minDate={new Date()}
            calendarStartDay={1}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2" className="form-label blue font_12">
          {t('unAvailability.table.end')}
        </Form.Label>
        <Col sm="10">
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            className={'form-control date-time blue'}
            timeInputLabel="Time:"
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeInput
            minDate={startDate}
            calendarStartDay={1}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2" className="form-label blue font_12">
          {t('unAvailability.table.frequency')}
        </Form.Label>
        <Col sm="10">
          <Form.Select defaultValue={206500000}>
            {frequencyLookup.map((value, index) => (
              <option value={value.id} key={index}>
                {value.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2" className="form-label blue font_12">
          {t('unAvailability.add.selectWeekDays')}
        </Form.Label>
        <Col sm="10">
          {weekDaysList.map((value, index) => (
            <Form.Check
              type="switch"
              id="custom-switch"
              label={<label className="form-label blue font_12">{value}</label>}
              onChange={() => {
                handleWeekDays(value);
              }}
              key={index}
              checked={weekDays.weekDays[value.toLowerCase()]}
            />
          ))}
        </Col>
      </Form.Group>
    </Form>
  );
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.loading,
    lookup: common.unavailabilityLookup,
    error: common.error,
    errorResponse: common.errorResponse,
    addResponse: common.unavailabilityAdd,
  };
}

export const AddUnavailabilityWrapper = connect(mapStateToProps)(AddUnavailability);
