import React from 'react';
import header_logo from '../../assets/images/header_logo.svg';
import main from '../../assets/images/main.svg';

export const Logo = () => {
  return (
    <>
      <div className="text-center">
        <img src={header_logo} className="img-fluid" alt="" />
      </div>
      <div className="text-center main_image">
        <div className="m_img">
          <img src={main} className="img-fluid" alt="" />
        </div>
        <div className="main_hr">
          <div className="hr"></div>
        </div>
      </div>
    </>
  );
};
