import React from 'react';
import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';

export const InterpreterBlock = ({ interpreter }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-white p-4 mt-5 mt-lg-0">
        <div className="interpreter_infor">
          <div className="interpreter_infor_heading">
            <p className="font_18 font_700 blue">{t('assignment.interpreterInfo')}</p>
          </div>
          <div className="row">
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="assignment_info mt-3">
                <p className="light_blue">{t('assignment.interpreterBase.name')}</p>
                <p className="blue font_18">{interpreter?.name}</p>
              </div>
              <div className="assignment_info mt-5 pt-3">
                <p className="light_blue">{t('assignment.interpreterBase.brithCountry')}</p>
                <p className="blue font_18">{interpreter?.birthCountry}</p>
              </div>
            </div>
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 pb-4">
              <div className="assignment_info mt-3">
                <p className="light_blue">{t('assignment.interpreterBase.gender')}</p>
                <p className="blue font_18">{interpreter?.gender}</p>
              </div>
              <div className="assignment_info mt-5 pt-3">
                <p className="light_blue">{t('assignment.interpreterBase.mobileNo')}</p>
                <p className="blue font_18">{interpreter?.contactNo}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 mt-4">
        <div className="interpreter_infor">
          <div className="interpreter_infor_heading">
            <p className="font_18 font_700 blue">{t('assignment.interpreterBase.detailsInterpreter')}</p>
          </div>
          <div className="row">
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 pb-5">
              <div className="assignment_info mt-3">
                <p className="light_blue">{t('assignment.actualStart')}</p>
                <p className="blue font_18">
                  {interpreter?.actualStartTime ? formatDate(interpreter?.actualStartTime) : ''}
                </p>
              </div>
            </div>
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="assignment_info mt-3">
                <p className="light_blue">{t('assignment.actualEnd')}</p>
                <p className="blue font_18">
                  {interpreter?.actualEndTime ? formatDate(interpreter?.actualEndTime) : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
