import i18n from 'i18next';
import enTranslation from './languageTranslations/en/en-translation.json';
import noTranslation from './languageTranslations/no/no-translation.json';
import { initReactI18next } from 'react-i18next';

const defaultLanguage = 'no';

export const resources = {
  en: {
    common: enTranslation,
  },
  no: {
    common: noTranslation,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: defaultLanguage,
  compatibilityJSON: 'v3',
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
