import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { InterpreterDataTableWrapper } from './InterpreterDataTable';
import { status } from '../../utils';
import { useTranslation } from 'react-i18next';
import { CommonFilterWrapper } from '../../uiComponents';


export const InterpreterHome = (props: any) => {
  const [assignmentStatus, setAssignmentStatus] = useState('All');
  const { t } = useTranslation();
  const [commonFilter, setCommonFilter] = useState({});

  const handleOnStatusChange = (e: any) => {
    setAssignmentStatus(e.target.value);
  };

  const handleCommonFilters = (filter) =>{
    setCommonFilter(filter);
  }

  return (
    <div className="bg_blue p-3 content">
      <div className="bg-white mt-3">
        <form className="f_form">
          <div className="row">
            <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-6 mt-2">
              <div className="ms-4 mb-4 me-2 me-md-0">
                <label className="form-label blue">{t('dashboard.table.record')}</label>
                <Form.Select defaultValue="All" onChange={handleOnStatusChange}>
                  {status.map((el, index) => (
                    <option value={el.key} key={index}>
                      {el.value}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <CommonFilterWrapper updateCommon={handleCommonFilters} t={t} userType={props?.userType}/>
          </div>
        </form>
      </div>
      <div className="table-responsive table_text_blue mt-4">
        <InterpreterDataTableWrapper
          userType={props?.userType}
          id={props?.userId}
          assignmentStatus={assignmentStatus}
          t={t}
          commonFilter={commonFilter}
        />
      </div>
    </div>
  );
};
