import { userTypeEnums } from '../interface';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import {getCurrentDateFormatted} from './dateUtils';

const listPayload = {
  searchstring: '',
  orderByContactId: '',
  pageIndex: 1,
  pageSize: 10,
  orderBy: 'STARTTIME',
  orderType: 'ASC',
  status: 'Active',
  fromDate:getCurrentDateFormatted()
};

export const getSearchPayload = (
  userType,
  id?,
  status?,
  orderByContactId?,
  searchstring?,
  pageIndex?,
  pageSize?,
  orderBy?,
  orderType?,
  statusReason?,
  fromDate?,
  toDate?,
) => {
  return {
    ...listPayload,
    ...(userType === userTypeEnums.CUSTOMER && { customerId: id }),
    ...(userType === userTypeEnums.INTERPRETER && { interpreterId: id }),
    ...(orderByContactId && { orderByContactId }),
    ...(status && { status }),
    ...(searchstring && { searchstring }),
    ...(pageIndex && { pageIndex }),
    ...(pageSize && { pageSize }),
    ...(orderBy && { orderBy }),
    ...(orderType && { orderType }),
    ...(statusReason && { statusReason }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
  };
};

export const filterKeyFromName = (collection, matchObject) => {
  const parseValue = filter(collection, matchObject);
  if (parseValue.length > 0) {
    return parseValue[0];
  } else {
    return null;
  }
};

export const sortArrayData = (collection, key) => {
  const sortArray = sortBy(collection, [key]);
  return sortArray;
};
