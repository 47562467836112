import React from 'react';
import './loader.css';

export const Loader = () => {
  return <div id="cover-spin"></div>;
};

export const DataTableLoader = () => {
  return (
    <div id="demo">
      <svg id="loading-spinner" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle id="loading-circle-meduim" cx="30" cy="30" r="27" stroke="#005CB9" strokeWidth={6} />
      </svg>
    </div>
  );
};
