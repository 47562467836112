import Axios from 'axios';

const env = process.env;

const apiClient = Axios.create({
  baseURL: env?.REACT_APP_BASE_URL ?? 'https://devclientserviceapint.azurewebsites.net/api/',
});

export const getRequest = async (getRoute: string, config?: any) => {
  return apiClient
    .get(getRoute, config ? config : null)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const postRequest = async (postRoute: string, postRequest: any, config?: any) => {
  return apiClient
    .post(postRoute, postRequest ?? null, config ? config : null)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const deleteRequest = async (getRoute: string, config?: any) => {
  return apiClient
    .delete(getRoute, config ? config : null)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
