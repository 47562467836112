import {
  FEEDBACK_LOOKUP_ERROR,
  FEEDBACK_LOOKUP_REQUEST,
  FEEDBACK_LOOKUP_SUCCESS,
  FEEDBACK_ERROR,
  FEEDBACK_REQUEST,
  FEEDBACK_SUCCESS,
} from '../reducer/commonReducer';
import { getRequest, postRequest } from '../../utils';

export const getFeedbackLookup = async (requestPayload, dispatch) => {
  try {
    dispatch(FEEDBACK_LOOKUP_REQUEST());
    const feedbackLookup = await getRequest(`/lookup/customerfeedback`);
    if (feedbackLookup.status === 200) {
      //@ts-ignore
      dispatch(FEEDBACK_LOOKUP_SUCCESS(feedbackLookup.data));
    } else {
      //@ts-ignore
      dispatch(FEEDBACK_LOOKUP_ERROR(feedbackLookup.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const addFeedback = async (requestPayload, dispatch) => {
  try {
    dispatch(FEEDBACK_REQUEST());
    const submitFeedback = await postRequest(`/customer/addfeedback`, requestPayload);
    if (submitFeedback.status === 200) {
      //@ts-ignore
      dispatch(FEEDBACK_SUCCESS(submitFeedback.data));
    } else {
      //@ts-ignore
      dispatch(FEEDBACK_ERROR(submitFeedback.data));
    }
  } catch (error) {
    console.error(error);
  }
};
