import { AppRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import { AuthProvider, GlobalProvider } from './utils';
import 'react-toastify/dist/ReactToastify.css';
import './i18n/config';

function App() {
  return (
    <main>
      <AuthProvider>
        <GlobalProvider>
          <ToastContainer />
          <AppRoutes />
        </GlobalProvider>
      </AuthProvider>
    </main>
  );
}

export default App;
