import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomerDataTableWrapper } from './CustomerDataTable';
import { status } from '../../utils';
import { CommonFilterWrapper } from '../../uiComponents';
import { GlobalContext } from '../../utils';
import { useTranslation } from 'react-i18next';

export const CustomerHome = (props: any) => {
  const { t } = useTranslation();
  const global = useContext(GlobalContext);
  const [assignmentStatus, setAssignmentStatus] = useState('Active');
  const [search, setSearch] = useState<string>(null);
  const [commonFilter, setCommonFilter] = useState({});

  const handleOnStatusChange = (e: any) => {
    setAssignmentStatus(e.target.value);
  };

  const onChangeSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const triggerSearch = () => {
    if (search !== null) {
      global.setTrigger(!global.trigger);
    }
  };

  const handleCommonFilters = (filter) =>{
    setCommonFilter(filter);
  }

  return (
    <div className="bg_blue p-3 content">
      <div className="bg-white mt-3">
        <form className="f_form">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <div className="mb-3 ms-4 mt-2">
                <label className="form-label blue">{t('dashboard.search')}</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={t('dashboard.placeholderSearch')}
                  onChange={onChangeSearch}
                  onBlur={triggerSearch}
                />
              </div>
            </div>
            <div className="col-12 col-xl-2 col-lg-2 col-md-4 col-sm-8 mt-2 ms-4 ms-md-0 text-box-width">
              <div className="dropdown status_dropdown">
                <label className="form-label blue">{t('dashboard.status')}</label>
                <Form.Select className="select-status" onChange={handleOnStatusChange}>
                  {status.map((el, index) => (
                    <option value={el.key} key={index}>
                      {el.value}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <CommonFilterWrapper updateCommon={handleCommonFilters} t={t} userType={props?.userType}/>
          </div>
        </form>
      </div>
      <div className="table-responsive table_text_blue mt-4">
        <CustomerDataTableWrapper
          userType={props?.userType}
          id={props?.userId}
          assignmentStatus={assignmentStatus}
          search={search}
          t={t}
          commonFilter={commonFilter}
        />
      </div>
    </div>
  );
};
