import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import fileUpload from '../../assets/images/upload-document.svg';
import { useTranslation } from 'react-i18next';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { warnAlert, successAlert } from '../ToastAlert';
import { uploadInterpreterDocument, resetOrder } from '../../redux/action';
import { useAppDispatch } from '../../config/hook';

const FileUpload = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState([]);
  const [customFileName, setCustomFileName] = useState([]);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      if (props?.response?.status === 200) {
        resetOrder(dispatch);
        setFiles([]);
        successAlert('Documents Upload successfully');
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }
    }
  }, [props.response]);

  const handleFileType = (e: any, index: number) => {
    const updateFileType = [...fileType];
    if (e.target.value == 0) {
      updateFileType[index] = null;
      setFileType(updateFileType);
    } else {
      updateFileType[index] = e.target.value;
      setFileType(updateFileType);
    }
  };

  const handleCustomFileName = (e: any, index: number) => {
    const updateCustomFileName = [...customFileName];
    updateCustomFileName[index] = e.target.value;
    setCustomFileName(updateCustomFileName);
  };

  const handleUpload = () => {
    let fileArray = [];
    if (files?.length === 0) {
      return 0;
    }

    if (files.length > 0) {
      const updateFileType = [...fileType];
      if (fileType.length === 0) {
        setFileType(updateFileType);
        return 0;
      } else {
        for (let index = 0; index < files.length; index++) {
          if (fileType[index] === null || fileType[index] === undefined) {
            setFileType(updateFileType);
            return 0;
          }
        }
      }
    }

    files.map((value, index) => {
      let document = {
        name: customFileName[index] || value?.name,
        documentTypeId: fileType[index],
        file: value,
      };
      fileArray.push(document);
    });
    
    uploadInterpreterDocument({ assignmentId: props?.Id }, fileArray, dispatch);
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      setFiles(acceptedFiles);
    } else if (fileRejections.length > 0) {
      fileRejections.map(({ file }) => {
        warnAlert(`${file.name} not allowed`);
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/*': ['.pdf', '.xlsx', '.xls', '.docx', '.doc'],
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
  });

  return (
    <div className="bg-white p-4" style={{ marginTop: '1rem' }}>
      <div className="interpreter_infor">
        <div className="interpreter_infor_heading">
          <Stack direction="horizontal" gap={3}>
            <label className="form-label blue font_12 me-auto">{t('order.documentTitle')}</label>
            <span className="place-order-hover me-3">
              <button type="button" className="btn accept" onClick={handleUpload}>
                {'upload'}
              </button>
            </span>
          </Stack>
          <div {...getRootProps()} className="drop-file" style={{ marginTop: '5px' }}>
            <img src={fileUpload} alt="" />
            <input {...getInputProps()} className="dropzone" />
            <p>{t('order.documentInfoText')}</p>
          </div>
          <aside>
            <label className="form-label blue font_12">{t('order.documentAccept')}</label>
            {files.map((file, index) => (
              <Stack direction="horizontal" gap={2} key={index}>
                <Form.Control type="text" placeholder={file.name} readOnly />
                <div className="vr" />
                    <div className="custom-filetype">
                      <Form.Select
                        onChange={(e) => {
                          handleFileType(e, index);
                        }}
                        isInvalid={
                          files.length > 0
                            ? fileType[index] === null || fileType[index] === undefined
                              ? true
                              : false
                            : false
                        }>
                        <option value={0}>{t('order.fileType')}</option>
                        {props?.documentType?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{t('order.require.fileTypeRequire')}</Form.Control.Feedback>
                    </div>
                    <div className="vr" />
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t('order.customFileName')}
                      aria-label="default input example"
                      onChange={(e) => {
                        handleCustomFileName(e, index);
                      }}
                    />
              </Stack>
            ))}
          </aside>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { order } = state;
  return {
    loading: order.loading,
    response: order.interpreterUploadDocumentResponse,
    error: order.error,
    errorResponse: order.errorResponse,
  };
}
export const FileUploadWrapper = connect(mapStateToProps)(FileUpload);
