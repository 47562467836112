import {
  FAVOURITE_INTERPRETER_ERROR,
  FAVOURITE_INTERPRETER_REQUEST,
  FAVOURITE_INTERPRETER_SUCCESS,
  MARK_FAVOURITE_INTERPRETER_ERROR,
  MARK_FAVOURITE_INTERPRETER_REQUEST,
  MARK_FAVOURITE_INTERPRETER_SUCCESS,
  MARK_FAVOURITE_INTERPRETER_RESET,
  REMOVE_FAVOURITE_INTERPRETER_ERROR,
  REMOVE_FAVOURITE_INTERPRETER_REQUEST,
  REMOVE_FAVOURITE_INTERPRETER_RESET,
  REMOVE_FAVOURITE_INTERPRETER_SUCCESS
} from '../reducer/commonReducer';
import { getRequest, postRequest, deleteRequest } from '../../utils';

export const getFavouriteInterpreterList = async (requestPayload, dispatch) => {
  try {
    dispatch(FAVOURITE_INTERPRETER_REQUEST());
    const favInterpreter = await getRequest(`/customer/favouriteinterpreter/list?customerId=${requestPayload}`);
    if (favInterpreter.status === 200) {
      //@ts-ignore
      dispatch(FAVOURITE_INTERPRETER_SUCCESS(favInterpreter.data));
    } else {
      //@ts-ignore
      dispatch(FAVOURITE_INTERPRETER_ERROR(favInterpreter.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const markFavInterpreter = async (requestPayload, dispatch) => {
  try {
    dispatch(MARK_FAVOURITE_INTERPRETER_REQUEST());
    const markFavInterpreter = await postRequest(`/customer/favouriteinterpreter/add`, requestPayload);
    if (markFavInterpreter.status === 200) {
      //@ts-ignore
      dispatch(MARK_FAVOURITE_INTERPRETER_SUCCESS(markFavInterpreter.data));
    } else {
      //@ts-ignore
      dispatch(MARK_FAVOURITE_INTERPRETER_ERROR(markFavInterpreter.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset Mark Interpreter
export async function resetMarkFav(dispatch) {
  dispatch(MARK_FAVOURITE_INTERPRETER_RESET());
}


export const removeFavInterpreter = async (requestPayload, dispatch) => {
  try {
    dispatch(REMOVE_FAVOURITE_INTERPRETER_REQUEST());
    const removeFavInterpreter = await deleteRequest(`/customer/favouriteinterpreter/remove/${requestPayload}`);
    if (removeFavInterpreter.status === 200) {
      //@ts-ignore
      dispatch(REMOVE_FAVOURITE_INTERPRETER_SUCCESS(removeFavInterpreter.data));
    } else {
      //@ts-ignore
      dispatch(REMOVE_FAVOURITE_INTERPRETER_ERROR(removeFavInterpreter.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset Remove Interpreter
export async function resetRemoveFav(dispatch) {
  dispatch(REMOVE_FAVOURITE_INTERPRETER_RESET());
}