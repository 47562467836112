import React from 'react';
import DatePicker from 'react-datepicker';

interface DateTimeInput {
  selected?: Date;
  onChangeDate?(value): any;
  classNameDate?: string;
  datePickerClass?: string;
  dateFormat?: string;
  selectedTime?: Date;
  onChangeTime?(value): any;
  classNameTime?: string;
  datePickerClassTime?: string;
  dateFormatTime?: string;
  minDate?:Date;
}

export const DateTimeInput = (props: DateTimeInput) => {
  return (
    <div className="row">
      <div className={props?.classNameDate ?? 'col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7'}>
        <DatePicker
          selected={props?.selected}
          onChange={props?.onChangeDate}
          className={props?.datePickerClass}
          dateFormat={props?.dateFormat}
          minDate={props?.minDate}
        />
      </div>
      <div className={props?.classNameTime ?? 'col-5 col-xl-5 col-lg-5 col-md-5 col-sm-5'}>
        <DatePicker
          selected={props?.selectedTime}
          onChange={props?.onChangeTime}
          className={props?.datePickerClassTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeFormat="HH:mm"
          dateFormat={props?.dateFormatTime}
        />
      </div>
    </div>
  );
};
