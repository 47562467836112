import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

export const Feedback = (props) => {
  const [qualityType, setQualityType] = useState('default');
  const [isInValid, setIsInValid] = useState<boolean>(false);
  const [freefeedbackText, setFreefeedbackText] = useState('');

  const onChangeQualityType = (e: any) => {
    setQualityType(e.target.value);
    if (e.target.value === 'default') {
      setIsInValid(true);
    } else {
      setIsInValid(false);
    }
  };

  const onChangeFreeText = (e: any) => {
    setFreefeedbackText(e.target.value);
  };

  const handleAddFeedback = () => {
    if (qualityType !== 'default' && !isInValid) {
      const payload = {
        qualityType,
        freefeedbackText,
      };
      props.submitFeedback(payload);
    }
  };

  return (
    <div className="bg-white p-4 mb-4">
      <div className="interpreter_infor">
        <div className="interpreter_infor_heading">
          <Stack direction="horizontal" gap={3}>
            <p className="font_18 font_700 blue me-auto" style={{ marginBottom: '0px' }}>
              {props.t('assignment.feedback.title')}
            </p>
            {!props?.minimalFeedback?.isFeedbackProvided && (
              <span className="place-order-hover me-3">
                <button type="button" className="btn accept pe-4 ps-4" onClick={handleAddFeedback}>
                  {props.t('assignment.feedback.addButton')}
                </button>
              </span>
            )}
          </Stack>
        </div>
        <div className="row">
          <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="assignment_info mt-3">
              <p className="light_blue">
                {!props?.minimalFeedback?.isFeedbackProvided ? props.t('assignment.feedback.qualityCaseType') : props.t('assignment.feedback.qualityCasePhase')}
              </p>
              {!props?.minimalFeedback?.isFeedbackProvided ? (
                <>
                  <Form.Select aria-label="Default select example" isInvalid={isInValid} onChange={onChangeQualityType}>
                    <option value={'default'}>{props.t('assignment.feedback.select')}</option>
                    {props?.feedbackLookup?.map((value) => (
                      <option value={value.id} key={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {props.t('assignment.feedback.qualityCaseRequired')}</Form.Control.Feedback>
                </>
              ) : (
                <>
                  <Badge className="badge-custom me-auto">{props?.minimalFeedback?.phaseType}</Badge>
                </>
              )}
            </div>
          </div>
          <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 pb-4">
            <div className="assignment_info pt-3">
              <p className="light_blue"> {props.t('assignment.feedback.feedbackTextBox')}</p>
              {!props?.minimalFeedback?.isFeedbackProvided ? (
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={3} onChange={onChangeFreeText} />
                </Form.Group>
              ) : (
                <p className="blue font_18">{props?.minimalFeedback?.resolution}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
