import {
  UNAVAILABILITY_LIST_ERROR,
  UNAVAILABILITY_LIST_SUCCESS,
  UNAVAILABILITY_LIST_REQUEST,
  UNAVAILABILITY_REMOVE_ERROR,
  UNAVAILABILITY_REMOVE_REQUEST,
  UNAVAILABILITY_REMOVE_SUCCESS,
  REMOVE_UNAVAILABILITY_REMOVE_RESET,
  UNAVAILABILITY_LOOKUP_REQUEST,
  UNAVAILABILITY_LOOKUP_ERROR,
  UNAVAILABILITY_LOOKUP_SUCCESS,
  UNAVAILABILITY_ADD_ERROR,
  UNAVAILABILITY_ADD_REQUEST,
  UNAVAILABILITY_ADD_SUCCESS
} from '../reducer/commonReducer';
import { getRequest, postRequest, deleteRequest } from '../../utils';

export const getUnavailableInterpreterList = async (requestPayload, dispatch) => {
  try {
    dispatch(UNAVAILABILITY_LIST_REQUEST());
    const unavailableList = await getRequest(
      `/interpreter/interpreterunavailability/list?interpreterId=${requestPayload}`,
    );
    if (unavailableList.status === 200) {
      //@ts-ignore
      dispatch(UNAVAILABILITY_LIST_SUCCESS(unavailableList.data));
    } else {
      //@ts-ignore
      dispatch(UNAVAILABILITY_LIST_ERROR(unavailableList.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUnavailableLookup = async (requestPayload, dispatch) => {
  try {
    dispatch(UNAVAILABILITY_LOOKUP_REQUEST());
    const unavailableLookup = await getRequest(`/lookup/interpreterUnavailability`);
    if (unavailableLookup.status === 200) {
      //@ts-ignore
      dispatch(UNAVAILABILITY_LOOKUP_SUCCESS(unavailableLookup.data));
    } else {
      //@ts-ignore
      dispatch(UNAVAILABILITY_LOOKUP_ERROR(unavailableLookup.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const addUnavailability = async (requestPayload, dispatch) => {
  try {
    dispatch(UNAVAILABILITY_ADD_REQUEST());
    const createUnavailability = await postRequest(`/interpreter/interpreterunavailability/add`, requestPayload);
    if (createUnavailability.status === 200) {
      //@ts-ignore
      dispatch(UNAVAILABILITY_ADD_SUCCESS(createUnavailability.data));
    } else {
      //@ts-ignore
      dispatch(UNAVAILABILITY_ADD_ERROR(createUnavailability.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeUnavailability = async (requestPayload, dispatch) => {
  try {
    dispatch(UNAVAILABILITY_REMOVE_REQUEST());
    const remove = await deleteRequest(`/interpreter/interpreterunavailability/remove/${requestPayload}`);
    if (remove.status === 200) {
      //@ts-ignore
      dispatch(UNAVAILABILITY_REMOVE_SUCCESS(remove.data));
    } else {
      //@ts-ignore
      dispatch(UNAVAILABILITY_REMOVE_ERROR(remove.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset Remove Interpreter
export async function resetRemoveUnavailable(dispatch) {
  dispatch(REMOVE_UNAVAILABILITY_REMOVE_RESET());
}
