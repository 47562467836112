import { IReduxAction } from '../../interface';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: {},
  error: false,
  errorResponse: {},
  loading: false,
  insideLoading: false,
  assignmentList: [],
  assignmentDetail: {},
  downloadAssignment: {},
  cancelAssignment: {},
  reportAssignment: {},
  statusReasonlookup: {},
  filterHistory: {},
  exportToExcel: {},
  documentLookup: {},
};

export const assignmentSlice = createSlice({
  name: 'assignment',
  initialState: initialState,
  reducers: {
    ASSIGNMENT_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ASSIGNMENT_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      };
    },
    ASSIGNMENT_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    STATUSREASON_LOOKUP_REQUEST: (state) => {
      return {
        ...state,
        insideLoading: true,
        error: false,
      };
    },
    STATUSREASON_LOOKUP_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        statusReasonlookup: action.payload,
        insideLoading: false,
        error: false,
      };
    },
    STATUSREASON_LOOKUP_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        insideLoading: false,
      };
    },
    ASSIGNMENT_LIST_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ASSIGNMENT_LIST_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        assignmentList: action.payload,
        loading: false,
        error: false,
      };
    },
    ASSIGNMENT_LIST_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    INTERPRETER_LIST_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    INTERPRETER_LIST_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        assignmentList: action.payload,
        loading: false,
        error: false,
      };
    },
    INTERPRETER_LIST_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    ASSIGNMENT_DETAIL_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ASSIGNMENT_DETAIL_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        assignmentDetail: action.payload,
        loading: false,
        error: false,
      };
    },
    ASSIGNMENT_DETAIL_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    ACCEPT_ASSIGNMENT_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ACCEPT_ASSIGNMENT_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      };
    },
    ACCEPT_ASSIGNMENT_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    REJECT_ASSIGNMENT_REQUEST: (state, action: IReduxAction) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    REJECT_ASSIGNMENT_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      };
    },
    REJECT_ASSIGNMENT_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    DOWNLOAD_ASSIGNMENT_PDF_REQUEST: (state, action: IReduxAction) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    DOWNLOAD_ASSIGNMENT_PDF_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        downloadAssignment: action.payload,
        loading: false,
        error: false,
      };
    },
    DOWNLOAD_ASSIGNMENT_PDF_RESET: (state, action: IReduxAction) => {
      return {
        ...state,
        downloadAssignment: {},
        loading: false,
        error: false,
      };
    },
    DOWNLOAD_ASSIGNMENT_PDF_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    CANCEL_ASSIGNMENT_REQUEST: (state, action: IReduxAction) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CANCEL_ASSIGNMENT_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        cancelAssignment: action.payload,
        loading: false,
        error: false,
      };
    },
    CANCEL_ASSIGNMENT_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        cancelAssignment: action.payload,
        errorResponse: action.payload,
        loading: false,
      };
    },
    REPORT_REQUEST: (state, action: IReduxAction) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    REPORT_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        reportAssignment: action.payload,
        loading: false,
        error: false,
      };
    },
    REPORT_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        reportAssignment: action.payload,
        errorResponse: action.payload,
        loading: false,
      };
    },
    FILTER_HISTORY: (state, action: IReduxAction) => {
      return {
        ...state,
        filterHistory: action.payload,
      };
    },
    EXPORT_TO_EXCEL_REQUEST: (state) => {
      return {
        ...state,
        insideLoading: true,
        error: false,
      };
    },
    EXPORT_TO_EXCEL_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        exportToExcel: action.payload,
        insideLoading: false,
        error: false,
      };
    },
    EXPORT_TO_EXCEL_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        exportToExcel: action.payload,
        errorResponse: action.payload,
        insideLoading: false,
      };
    },
    EXPORT_TO_EXCEL_RESET: (state) => {
      return {
        ...state,
        exportToExcel: {},
        errorResponse: {},
      };
    },
    ASSIGNMENT_RESET: () => {
      return {
        ...initialState,
      };
    },
    DOCUMENT_LOOKUP_REQUEST: (state, action: IReduxAction) => {
      return {
        ...state,
        error: false,
      };
    },
    DOCUMENT_LOOKUP_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        documentLookup: action.payload,
        error: false,
      };
    },
    DOCUMENT_LOOKUP_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        documentLookup: action.payload,
        errorResponse: action.payload,
      };
    },
  },
});

export const {
  ASSIGNMENT_ERROR,
  ASSIGNMENT_REQUEST,
  ASSIGNMENT_SUCCESS,
  ACCEPT_ASSIGNMENT_ERROR,
  ACCEPT_ASSIGNMENT_REQUEST,
  ACCEPT_ASSIGNMENT_SUCCESS,
  REJECT_ASSIGNMENT_ERROR,
  REJECT_ASSIGNMENT_REQUEST,
  REJECT_ASSIGNMENT_SUCCESS,
  ASSIGNMENT_LIST_ERROR,
  ASSIGNMENT_LIST_REQUEST,
  ASSIGNMENT_LIST_SUCCESS,
  ASSIGNMENT_DETAIL_ERROR,
  ASSIGNMENT_DETAIL_REQUEST,
  ASSIGNMENT_DETAIL_SUCCESS,
  INTERPRETER_LIST_ERROR,
  INTERPRETER_LIST_REQUEST,
  INTERPRETER_LIST_SUCCESS,
  DOWNLOAD_ASSIGNMENT_PDF_ERROR,
  DOWNLOAD_ASSIGNMENT_PDF_REQUEST,
  DOWNLOAD_ASSIGNMENT_PDF_SUCCESS,
  DOWNLOAD_ASSIGNMENT_PDF_RESET,
  ASSIGNMENT_RESET,
  CANCEL_ASSIGNMENT_ERROR,
  CANCEL_ASSIGNMENT_REQUEST,
  CANCEL_ASSIGNMENT_SUCCESS,
  REPORT_ERROR,
  REPORT_REQUEST,
  REPORT_SUCCESS,
  STATUSREASON_LOOKUP_ERROR,
  STATUSREASON_LOOKUP_REQUEST,
  STATUSREASON_LOOKUP_SUCCESS,
  FILTER_HISTORY,
  EXPORT_TO_EXCEL_ERROR,
  EXPORT_TO_EXCEL_REQUEST,
  EXPORT_TO_EXCEL_SUCCESS,
  EXPORT_TO_EXCEL_RESET,
  DOCUMENT_LOOKUP_ERROR,
  DOCUMENT_LOOKUP_REQUEST,
  DOCUMENT_LOOKUP_SUCCESS,
} = assignmentSlice.actions;
