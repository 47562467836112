export enum routeEnums {
  CUSTOMER_DASHBOARD = '/customer/dashboard',
  INTERPRETER_DASHBOARD = '/interpreter/dashboard',
  ORDER = '/order',
  FAVOURITE = '/favourite',
  DOCUMENT = '/document',
  PUBLICATION = '/publication',
  UNAVAILABILITY = '/unavailable',
}

export enum statusEnums {
  ACTIVE = 'Active',
  PREVIOUS = 'Previous',
  ALLASSIGNMENTS = 'All',
}

export enum userTypeEnums {
  CUSTOMER = 206500000,
  INTERPRETER = 206500001,
}

export enum sortFieldValueEnums {
  assignmentNo = 'ASSIGNMENTNO',
  interpreter = 'INTERPRETER',
  startTime = 'STARTTIME',
  assignmentType = 'ASSIGNMENT TYPE',
  orderByContactName = 'ORDER BY CONTACT NAME',
  additionalContact = 'ADDITIONAL CONTACT',
  language = 'LANGUAGE',
  account = 'ACCOUNT',
  status = 'STATUS REASON',
}

export enum sortTypeEnum {
  asc = 'ASC',
  desc = 'DESC',
}

export enum statusReasonEnmus {
  NEW = 'Nytt oppdrag (Utkast)',
  INWORKINGPROGRESS = 'I arbeid (Se notat)',
  INQUIRYSENT = 'Forespørsel sendt (Påmelding)',
  BACKFROMREQUEST = 'Tilbake fra forespørsel (Ikke tildelt)',
  NOTRESOLVED = 'Ikke løst',
  RESOLVED = 'Tildelt (Pågår)',
  DONE = 'Ferdig/Lønnet/Fakturert',
  CANCELLEDPROCESSED = 'Kansellert',
}


export enum travelTypeEnum {
  FIXED = 'Fast',
  VARIABLE = 'Variabel'
}