import {
  ORDER_LOOKUP_ERROR,
  ORDER_LOOKUP_SUCCESS,
  ORDER_LOOKUP_REQUEST,
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_ERROR,
  RESET_ORDER,
  EDIT_ORDER_ERROR,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  INTERPRETER_DOCUMENT_UPLOAD_ERROR,
  INTERPRETER_DOCUMENT_UPLOAD_REQUEST,
  INTERPRETER_DOCUMENT_UPLOAD_SUCCESS,
} from '../reducer/orderReducer';
import { postRequest, getRequest } from '../../utils';

export const getOrderlookup = async (requestPayload, dispatch) => {
  try {
    dispatch(ORDER_LOOKUP_REQUEST());
    const lookupOrder = await getRequest(
      `/lookup/customerOrder/${requestPayload?.customerId}/${requestPayload?.orderByContactId}`,
    );
    if (lookupOrder.data.status === 200) {
      //@ts-ignore
      dispatch(ORDER_LOOKUP_SUCCESS(lookupOrder.data));
    } else {
      //@ts-ignore
      dispatch(ORDER_LOOKUP_ERROR(lookupOrder.data));
    }
  } catch (error) {
    console.error(error);
  }
};

const objectToFormData = (createOrderPayload) => {
  const formData = new FormData();
  Object.entries(createOrderPayload).map(([key, value]) => {
    //@ts-ignore
    formData.append(key, value);
  });
  return formData;
};

export const createOrder = async (requestPayload, fileUploadData, dispatch?) => {
  try {
    dispatch(ORDER_REQUEST());
    const formDataPayload = objectToFormData(requestPayload);
    fileUploadData.map((item, i) => {
      formDataPayload.append(`AssignmentDocuments[${i}].documentTypeId`, item.documentTypeId);
      formDataPayload.append(`AssignmentDocuments[${i}].name`, item.name);
      formDataPayload.append(`AssignmentDocuments[${i}].file`, item.file);
    });
    const createOrder = await postRequest(`/customer/createOrder`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (createOrder.data.status === 201) {
      //@ts-ignore
      dispatch(ORDER_SUCCESS(createOrder.data));
    } else {
      //@ts-ignore
      dispatch(ORDER_ERROR(createOrder.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const editOrder = async (requestPayload, fileUploadData, dispatch) => {
  try {
    dispatch(EDIT_ORDER_REQUEST());
    const formDataPayload = objectToFormData(requestPayload);
    fileUploadData.map((item, i) => {
      formDataPayload.append(`AssignmentDocuments[${i}].documentTypeId`, item.documentTypeId);
      formDataPayload.append(`AssignmentDocuments[${i}].name`, item.name);
      formDataPayload.append(`AssignmentDocuments[${i}].file`, item.file);
    });
    const editOrder = await postRequest(`/customer/editOrder`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (editOrder.data.status === 200) {
      //@ts-ignore
      dispatch(EDIT_ORDER_SUCCESS(editOrder.data));
    } else {
      //@ts-ignore
      dispatch(EDIT_ORDER_ERROR(editOrder.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadInterpreterDocument = async (requestPayload, fileUploadData, dispatch?) => {
  try {
    dispatch(INTERPRETER_DOCUMENT_UPLOAD_REQUEST());
    const formDataPayload = objectToFormData(requestPayload);
    fileUploadData.map((item, i) => {
      formDataPayload.append(`documents[${i}].documentTypeId`, item.documentTypeId);
      formDataPayload.append(`documents[${i}].name`, item.name);
      formDataPayload.append(`documents[${i}].file`, item.file);
    });
    const uploadDocument = await postRequest(`/interpreter/uploadDocument`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (uploadDocument?.data.status === 200) {
      //@ts-ignore
      dispatch(INTERPRETER_DOCUMENT_UPLOAD_SUCCESS(uploadDocument?.data));
    } else {
      //@ts-ignore
      dispatch(INTERPRETER_DOCUMENT_UPLOAD_ERROR(uploadDocument?.data));
    }
  } catch (error) {
    console.error(error);
  }
};

//Reset Mark Interpreter
export async function resetOrder(dispatch) {
  dispatch(RESET_ORDER());
}
