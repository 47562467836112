import { toast } from 'react-toastify';

const option = (config) => {
  return {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 6000,
    hideProgressBar: true,
    ...config,
  };
};

export const successAlert = (message: string, config = null) => {
  toast.success(message, option(config));
};

export const infoAlert = (message: string, config = null) => {
  toast.info(message, option(config));
};

export const warnAlert = (message: string, config = null) => {
  toast.warn(message, option(config));
};

export const errorAlert = (message: string, config = null) => {
  toast.error(message, option(config));
};
