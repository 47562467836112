import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//import { RequestAssignmentStyle } from './RequestTicket.styled';
import { useAppDispatch } from '../../config/hook';
import './RequestAssignment.css';
import { Request } from './Request';
import { Logo } from './Logo';
import { Footer } from './Footer';
import { Loader } from '../Loader';
import { getAssignment, acceptAssignment, rejectAssignment } from '../../redux/action';
import { useParams } from 'react-router-dom';

const AssignmentWithLoader = () => {
  return (
    <main>
      <div className="container">
        <section className="mt-5">
          <div className="row justify-content-center">
            <div className="col-6 assignment">
              <Logo />
              <Loader />
            </div>
          </div>
        </section>
      </div>
      <div className="hr mt-4"></div>
      <Footer />
    </main>
  );
};

const AssignmentWithError = (props) => {
  return (
    <>
      <div className="container">
        <section className="mt-5">
          <div className="row justify-content-center">
            <div className="col-6 assignment">
              <Logo />
              <div className='assignment-message-info'>
                <p className="font_18 blue">{props?.errorDetails?.message}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="hr mt-4"></div>
      <Footer />
    </>
  );
};

const RequestAssignment = (props) => {
  const dispatch = useAppDispatch();
  const getParams = useParams();

  useEffect(() => {
    getAssignment(`${getParams.id}`, dispatch);
  }, []);

  const assignmentAcceptOrReject = async (e, action: string) => {
    e.preventDefault();
    if (action === 'accept') {
      acceptAssignment(`${getParams.id}`, dispatch);
    } else if (action === 'reject') {
      rejectAssignment(`${getParams.id}`, dispatch);
    }
  };

  if (props.loading) return <AssignmentWithLoader />;

  if (props.error) return <AssignmentWithError errorDetails={props.errorResponse} />;

  return (
    <>
      <div className="container">
        <section className="mt-5">
          <div className="row justify-content-center">
            <div className="col-6 assignment">
              <Logo />
              <Request assignment={props.response} onClick={assignmentAcceptOrReject} />
            </div>
          </div>
        </section>
      </div>
      <div className="hr mt-4"></div>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  const { assignment } = state;
  return {
    loading: assignment.loading,
    response: assignment.response,
    error: assignment.error,
    errorResponse: assignment.errorResponse,
  };
}

export const RequestAssignmentWrapper = connect(mapStateToProps)(RequestAssignment);
