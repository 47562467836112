import { statusEnums } from '../../interface';
export const status = [
  {
    key: statusEnums.ACTIVE,
    value: 'Pågående oppdrag',
  },
  {
    key: statusEnums.PREVIOUS,
    value: 'Fullførte oppdrag',
  },
  {
    key: statusEnums.ALLASSIGNMENTS,
    value: 'Alle oppdrag',
  },
];

export const statusReason = [
  {
    key: '1',
    value: 'New',
  },
  {
    key: '2',
    value: 'In Progress',
  },
  {
    key: '3',
    value: 'Back from Request',
  },
  {
    key: '4',
    value: 'Resolved',
  },
  {
    key: '5',
    value: 'Done',
  },
  {
    key: '6',
    value: 'CANCELLED PROCESSED',
  },
];