import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { GlobalContext } from '../../utils';
import { removeFavInterpreter, resetRemoveFav } from '../../redux/action';
import { Loader } from '../Loader';
import remove from '../../assets/images/remove-favourite.svg';
import './favourite.css';

export const RemoveFavourite = ({ row, ...props }) => {
  const global = useContext(GlobalContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      if(props?.response?.message === 'Success'){
        resetRemoveFav(dispatch);
        global.setTrigger(!global.trigger);
      }
    }
  }, [props.response]);

  const handleFavouriteRemove = () => {
    removeFavInterpreter(row?.favouriteInterpreterId, dispatch);
  };

  if (props?.loading) return <Loader />;

  return (
    <button className="btn-remove" onClick={handleFavouriteRemove}>
      <img src={remove} height={'36px'} />
    </button>
  );
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.loading,
    response: common.removeResponse,
    error: common.error,
    errorResponse: common.errorResponse,
  };
}

export const RemoveFavouriteWrapper = connect(mapStateToProps)(RemoveFavourite);
