import { IReduxAction } from '../../interface';
import { createSlice } from '@reduxjs/toolkit';
import { sortArrayData } from '../../utils';

function updateSortValue(payload) {
  const updatedSort = sortArrayData(payload.data, 'sortOrder');
  return { ...payload, data: updatedSort };
}

const initialState = {
  response: {},
  error: false,
  errorResponse: {},
  loading: false,
};

export const publicationSlice = createSlice({
  name: 'publication',
  initialState: initialState,
  reducers: {
    PUBILICATION_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    PUBILICATION_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: updateSortValue(action.payload),
        loading: false,
        error: false,
      };
    },
    PUBILICATION_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
  },
});

export const { PUBILICATION_ERROR, PUBILICATION_REQUEST, PUBILICATION_SUCCESS } = publicationSlice.actions;
