import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { removeUnavailability, resetRemoveUnavailable } from '../../redux/action';
import { Loader } from '../Loader';
import { MdFreeCancellation } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../utils';

export const RemoveUnAvailability = ({ row, ...props }) => {
  const dispatch = useAppDispatch();
  const global = useContext(GlobalContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      if (props?.response?.status === 200) {
        resetRemoveUnavailable(dispatch);
        global.setTrigger(!global.trigger);
      }
    }
  }, [props.response]);

  const handleFavouriteRemove = () => {
    removeUnavailability(row?.interpreterUnavailabilityId, dispatch);
  };

  if (props?.loading) return <Loader />;

  return (
    <OverlayTrigger
      key={'left'}
      placement={'left'}
      overlay={
        <Tooltip id={`tooltip-left}`}>
          <strong>{t('unAvailability.table.remove')}</strong>.
        </Tooltip>
      }>
      <button className="btn-remove" onClick={handleFavouriteRemove}>
        <MdFreeCancellation size={'36px'} className="blue" />
      </button>
    </OverlayTrigger>
  );
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.loading,
    response: common.unavailabilityRemove,
    error: common.error,
    errorResponse: common.errorResponse,
  };
}

export const RemoveUnAvailabilityWrapper = connect(mapStateToProps)(RemoveUnAvailability);
