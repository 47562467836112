import React from 'react';
import fbIcon from '../../assets/images/facebook.svg';

export const Footer = () => {
  return (
    <>
      <div className="hr"></div>
      <footer className="bg_blue">
        <div className="container">
          <div className="foot_text pt-4 pb-4">
            <div className="footer_d">
              <p className="blue">
                <a
                  href="https://www.nordtolk.no/_files/ugd/5e979a_8b50cf683a7141978e7f015327a67dc8.pdf"
                  target={'_blank'}
                  rel="noopener noreferrer">
                  Personvernserklæring
                </a>
                <span className="f_none ms-2 me-2">|</span>
              </p>
              <p className="blue">
                <a
                  href="https://www.nordtolk.no/_files/ugd/5e979a_b81f71414db14d4caef29a13fb5c88f8.pdf"
                  target={'_blank'}
                  rel="noopener noreferrer">
                  Privacy Policy
                </a>
                <span className="f_none ms-2 me-2">|</span>
              </p>
              <p className="blue">
                <a
                  href="https://www.nordtolk.no/_files/ugd/5e979a_567829e406a1434a8ee154c8460274a6.pdf"
                  target={'_blank'}
                  rel="noopener noreferrer">
                  Informasjonskapsler
                </a>
                <span className="f_none ms-2 me-2">|</span>
              </p>
              <p className="blue">
                <a
                  href="https://www.nordtolk.no/_files/ugd/5e979a_183d8ff1f65b4016960a3006b5cdc555.pdf"
                  target={'_blank'}
                  rel="noopener noreferrer">
                  Cookie policy
                </a>
              </p>
            </div>
            <p className="blue"> </p>
            <div className="ms-auto">
              <a href="http://www.facebook.com/nordtolk/" target="_blank" rel="noopener noreferrer">
                <img src={fbIcon} className="img-fluid fb" alt="" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
