import React, { useState, useEffect } from 'react';
import './profile.css';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { SubHeader } from '../../uiComponents';
import { userTypeEnums } from '../../interface';
import ListGroup from 'react-bootstrap/ListGroup';
import { Loader } from '../Loader';
import { getProfile } from '../../redux/action';
import { formatDate, DateFormats } from '../../utils';
import { useTranslation } from 'react-i18next';

export const Profile = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const leftContent = {
    title: t('profile.title'),
    breadcrumb: `Home / ${props.userType} ${t('profile.title')}`,
  };
  const [profile, setProfile] = useState<any>({});

  useEffect(() => {
    const payload = `userID=${props?.userId}`;
    getProfile(payload, dispatch);
  }, []);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      setProfile(props?.response?.data);
    }
  }, [props.response]);

  return (
    <div className="bg_blue p-3 content">
      <div className="mt-2 pb-2">
        <SubHeader isDisplay={false} leftContent={leftContent} />
        {props.loading && <Loader />}
        {props.error && <p className="error">{props?.errorResponse?.message}</p>}
        <div className="p-3">
          <div className="bg-white">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="pt-5 pb-md-5 ps-4">
                      {props?.userType === userTypeEnums.INTERPRETER && (
                        <div className="assignment_info">
                          <p className="light_blue">{t('profile.interpreterId')}</p>
                          <p className="blue font_18">{profile?.interpreterId}</p>
                        </div>
                      )}
                      <div className="assignment_info">
                        <p className="light_blue">{t('profile.name')}</p>
                        <p className="blue font_18">{profile?.name}</p>
                      </div>
                      <div className="assignment_info">
                        <p className="light_blue">{t('profile.mobileNo')}</p>
                        <p className="blue font_18">{profile?.contactNo}</p>
                      </div>
                      <div className="assignment_info">
                        <p className="light_blue">{t('profile.birthday')}</p>
                        <p className="blue font_18">
                          {profile?.birthDate ? formatDate(profile?.birthDate, DateFormats.DATE) : ''}
                        </p>
                      </div>
                      <div className="assignment_info">
                        <p className="light_blue">{t('profile.address')}</p>
                        <p className="blue font_18">{profile?.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    <div className="pt-md-5 pt-4 pb-5 ps-4">
                      <div className="assignment_info">
                        <p className="light_blue">{t('profile.email')}</p>
                        <p className="blue font_18">{profile?.email}</p>
                      </div>
                      <div className="assignment_info">
                        <p className="light_blue">{t('profile.telephone')}</p>
                        <p className="blue font_18">{profile?.telephoneNo}</p>
                      </div>
                      {profile?.taskTypes !== undefined && profile?.taskTypes.length > 0 && (
                        <div className="assignment_info">
                          <p className="light_blue">{t('profile.acceptTasksTypes')}</p>
                          {profile.taskTypes.map(
                            (val, index) =>
                              val.isSelected && (
                                <label key={index} className="form-check-label" style={{ marginRight: '1rem' }}>
                                  <span className="blue font_18">{val.name}</span>
                                </label>
                              ),
                          )}
                        </div>
                      )}
                      {profile?.languageSkills !== undefined && props?.userType === userTypeEnums.INTERPRETER && (
                        <div className="assignment_info">
                          <p className="light_blue">{t('profile.languageSkills')}</p>
                          <div className="row">
                            {profile.languageSkills.map((value, index) => (
                              <ListGroup horizontal key={index}>
                                <ListGroup.Item>
                                  <p className="blue font_18">{value.name}</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <p className="blue font_18">{value.isNative ? 'Yes' : '\u00A0No'}</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                  <p className="blue font_18">{value.skillLevel}</p>
                                </ListGroup.Item>
                              </ListGroup>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return {
    loading: user.loading,
    response: user.profile,
    error: user.error,
    errorResponse: user.errorResponse,
  };
}

export const ProfileWrapper = connect(mapStateToProps)(Profile);
