import { PUBILICATION_ERROR, PUBILICATION_REQUEST, PUBILICATION_SUCCESS } from '../reducer/publicationReducer';
import { postRequest } from '../../utils';

export const getPublication = async (requestPayload, dispatch) => {
  try {
    dispatch(PUBILICATION_REQUEST());
    const publication = await postRequest(`/publication/getlist`, requestPayload);
    if (publication.status === 200) {
      //@ts-ignore
      dispatch(PUBILICATION_SUCCESS(publication.data));
    } else {
      //@ts-ignore
      dispatch(PUBILICATION_ERROR(publication.data));
    }
  } catch (error) {
    console.error(error);
  }
};
