import React, { useState, useEffect, useContext } from 'react';
import './layout.css';
import classNames from 'classnames';
import header_logo from '../../assets/images/header_logo.svg';
//import travel from '../../assets/images/travel.svg';
import home from '../../assets/images/home.svg';
import homeBlue from '../../assets/images/home_blue.svg';
import order from '../../assets/images/order.svg';
import orderWhite from '../../assets/images/order_white.svg';
import favorite from '../../assets/images/favorite.svg';
import favoriteWhite from '../../assets/images/favorite_white.svg';
import publication from '../../assets/images/publication.svg';
import publicationWhite from '../../assets/images/publication_white.svg';
import document from '../../assets/images/document.svg';
import available from '../../assets/images/Interpreter-avaibility.svg';
import user from '../../assets/images/user.svg';
import profile from '../../assets/images/alina.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routeEnums, userTypeEnums, IHeaderProps } from '../../interface';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import { GlobalContext } from '../../utils';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  let location_nav = useLocation();
  let navigate = useNavigate();
  const { t } = useTranslation();
  let userResponse: any = JSON.parse(localStorage.getItem('userSettings'));
  const [navCollapse, setNavCollapse] = useState<boolean>(true);
  const global = useContext(GlobalContext);

  // if (userResponse === undefined) {
  //   localStorage.clear();
  //   navigate('/');
  // }

  useEffect(() => {
    if (userResponse) {
      global.setRelatedCustomer(userResponse?.defaultCustomer);
    }
  }, []);

  const toggleNavBar = () => {
    setNavCollapse(!navCollapse);
  };

  const matchRoutes = (inputRoute): boolean => {
    if (inputRoute === location_nav.pathname) {
      return true;
    } else {
      return false;
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleRelatedCustomer = (e: any) => {
    global.setRelatedCustomer(e.target.value);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light mt-2">
      <div className="container-fluid">
        {userResponse?.userType === userTypeEnums.INTERPRETER && (
          <Link to={routeEnums.INTERPRETER_DASHBOARD}>
            <img src={header_logo} alt="" />
          </Link>
        )}
        {userResponse?.userType === userTypeEnums.CUSTOMER && (
          <Link to={routeEnums.CUSTOMER_DASHBOARD}>
            <img src={header_logo} alt="" />
          </Link>
        )}
        <button
          className={classNames('navbar-toggler', { collapsed: navCollapse })}
          type="button"
          aria-expanded={!navCollapse}
          onClick={toggleNavBar}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={classNames('collapse navbar-collapse', { show: !navCollapse })} id="navbarSupportedContent">
          {userResponse?.userType === userTypeEnums.INTERPRETER ? (
            <InterpreterHeader
              matchRoutes={matchRoutes}
              userResponse={userResponse}
              handleProfile={handleProfile}
              handleLogout={handleLogout}
              t={t}
            />
          ) : (
            <CustomerHeader
              matchRoutes={matchRoutes}
              userResponse={userResponse}
              handleProfile={handleProfile}
              handleLogout={handleLogout}
              handleRelatedCustomer={handleRelatedCustomer}
              t={t}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

const CustomerHeader = (props) => {
  const [customer] = useState(props?.userResponse?.relatedCustomer ?? []);
  return (
    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li
        className={classNames('nav-item me-xl-5 me-md-1 h_margin r_margin', {
          nav_home: props.matchRoutes(routeEnums.CUSTOMER_DASHBOARD),
        })}>
        <Link
          to={routeEnums.CUSTOMER_DASHBOARD}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.CUSTOMER_DASHBOARD),
            white: props.matchRoutes(routeEnums.CUSTOMER_DASHBOARD),
            blue: !props.matchRoutes(routeEnums.CUSTOMER_DASHBOARD),
          })}>
          <img
            src={props.matchRoutes(routeEnums.CUSTOMER_DASHBOARD) ? home : homeBlue}
            className="me-lg-- me-2"
            alt=""
          />
          {props.t('header.home')}
        </Link>
      </li>
      <li
        className={classNames('nav-item me-xl-5 ms-xl-2 me-md-2 mb-3 mb-lg-0 mt-3 mt-lg-0 res_m h_margin', {
          nav_home: props.matchRoutes(routeEnums.ORDER),
        })}>
        <Link
          to={routeEnums.ORDER}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.ORDER),
            white: props.matchRoutes(routeEnums.ORDER),
            blue: !props.matchRoutes(routeEnums.ORDER),
          })}>
          <img src={props.matchRoutes(routeEnums.ORDER) ? orderWhite : order} className="me-2" alt="" />
          {props.t('header.order')}
        </Link>
      </li>
      <li
        className={classNames('nav-item me-xl-5 ms-xl-2 me-md-2 res_m h_margin', {
          nav_home: props.matchRoutes(routeEnums.FAVOURITE),
        })}>
        <Link
          to={routeEnums.FAVOURITE}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.FAVOURITE),
            white: props.matchRoutes(routeEnums.FAVOURITE),
            blue: !props.matchRoutes(routeEnums.FAVOURITE),
          })}>
          <img src={props.matchRoutes(routeEnums.FAVOURITE) ? favoriteWhite : favorite} className="me-2" alt="" />
          {props.t('header.favorite')}
        </Link>
      </li>
      <li
        className={classNames('nav-item me-xl-5 ms-xl-2 me-md-2 res_m h_margin', {
          nav_home: props.matchRoutes(routeEnums.PUBLICATION),
        })}>
        <Link
          to={routeEnums.PUBLICATION}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.PUBLICATION),
            white: props.matchRoutes(routeEnums.PUBLICATION),
            blue: !props.matchRoutes(routeEnums.PUBLICATION),
          })}>
          <img src={props.matchRoutes(routeEnums.PUBLICATION) ? publicationWhite : publication} className="me-2" alt="" />
          {props.t('header.publication')}
        </Link>
      </li>
      <li className="nav-item dropdown d-flex align-items-center me-3 ms-md-1 ms-xl-3 mb-3 mb-lg-0 mt-3 mt-lg-0">
        <Stack direction="horizontal" gap={2}>
          <img src={user} className="alina_img" alt="" />
          {customer.length > 0 && (
            <Form.Select
              className="customer-select"
              onChange={props?.handleRelatedCustomer}
              defaultValue={props?.userResponse?.defaultCustomer}>
              <option value={'default'}>{props.t('header.defaultDropdown')}</option>
              {customer.map((val, index) => (
                <option value={val.customerID} key={index}>
                  {val.customerName}
                </option>
              ))}
            </Form.Select>
          )}
        </Stack>
      </li>
      <ProfileHeader
        userResponse={props.userResponse}
        handleProfile={props.handleProfile}
        handleLogout={props.handleLogout}
        t={props.t}
      />
    </ul>
  );
};

const InterpreterHeader = (props) => {
  return (
    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li
        className={classNames('nav-item me-xl-5 me-md-1 h_margin r_margin', {
          nav_home: props.matchRoutes(routeEnums.INTERPRETER_DASHBOARD),
        })}>
        <Link
          to={routeEnums.INTERPRETER_DASHBOARD}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.INTERPRETER_DASHBOARD),
            white: props.matchRoutes(routeEnums.INTERPRETER_DASHBOARD),
            blue: !props.matchRoutes(routeEnums.INTERPRETER_DASHBOARD),
          })}>
          <img
            src={props.matchRoutes(routeEnums.INTERPRETER_DASHBOARD) ? home : homeBlue}
            className="me-lg-- me-2"
            alt=""
          />
          {props.t('header.home')}
        </Link>
      </li>
      <li
        className={classNames('nav-item me-xl-5 ms-xl-2 me-md-2 res_m h_margin', {
          nav_home: props.matchRoutes(routeEnums.DOCUMENT),
        })}>
        <Link
          to={routeEnums.DOCUMENT}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.DOCUMENT),
            white: props.matchRoutes(routeEnums.DOCUMENT),
            blue: !props.matchRoutes(routeEnums.DOCUMENT),
          })}>
          <img src={document} className="me-2" alt="" />
          {props.t('header.document')}
        </Link>
      </li>
      <li
        className={classNames('nav-item me-xl-5 ms-xl-2 me-md-2 res_m h_margin', {
          nav_home: props.matchRoutes(routeEnums.PUBLICATION),
        })}>
        <Link
          to={routeEnums.PUBLICATION}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.PUBLICATION),
            white: props.matchRoutes(routeEnums.PUBLICATION),
            blue: !props.matchRoutes(routeEnums.PUBLICATION),
          })}>
          <img src={props.matchRoutes(routeEnums.PUBLICATION) ? publicationWhite : publication} className="me-2" alt="" />
          {props.t('header.publication')}
        </Link>
      </li>
      <li
        className={classNames('nav-item me-xl-5 ms-xl-2 me-md-2 res_m h_margin', {
          nav_home: props.matchRoutes(routeEnums.UNAVAILABILITY),
        })}>
        <Link
          to={routeEnums.UNAVAILABILITY}
          className={classNames('nav-link', {
            active: props.matchRoutes(routeEnums.UNAVAILABILITY),
            white: props.matchRoutes(routeEnums.UNAVAILABILITY),
            blue: !props.matchRoutes(routeEnums.UNAVAILABILITY),
          })}>
          <img src={available} className="me-2" alt="" />
          {props.t('header.availability')}
        </Link>
      </li>
      <ProfileHeader
        userResponse={props.userResponse}
        handleProfile={props.handleProfile}
        handleLogout={props.handleLogout}
        t={props.t}
      />
    </ul>
  );
};
const ProfileHeader = (props) => {
  return (
    <li className="nav-item dropdown d-flex align-items-center me-3 ms-md-1 ms-xl-4">
      <Stack direction="horizontal" gap={2}>
        {/* <img src={profile} className="alina_img" alt="" /> */}
        <Dropdown className="d-inline">
          <Dropdown.Toggle id="dropdown-autoclose-true">{props?.userResponse?.name}</Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item onClick={props?.handleProfile}>{props.t('header.profile')}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={props?.handleLogout}>{props.t('header.logout')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Stack>
    </li>
  );
};
