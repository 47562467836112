import React, { useState, useEffect } from 'react';
import { ISubHeader } from '../../interface';
import start_blank from '../../assets/images/star_fill.png';
import { useTranslation } from 'react-i18next';
import './sub-header.css';

export const SubHeader = (props: ISubHeader) => {
  const { t } = useTranslation();
  const [favourite] = useState(props?.favButtonDisplay ?? false);

  const handleFeedbackClick = () => {
    props.feedbackOnClick();
  };

  const handleCancelClick = () => {
    props.cancelOnClick();
  };

  const handleFavClick = () => {
    props.favOnClick();
  };

  const handleEditClick = () => {
    props.editOnClick();
  };

  return (
    <div className="d-flex">
      <div className="main_assignment">
        <p className="blue font_700 font_24">{props?.leftContent?.title}</p>
        <span className="blue">{props?.leftContent?.breadcrumb}</span>
        <span className="blue font_18" style={{ marginLeft: '1rem' }}>
          {props?.rightContent?.displayContent}
        </span>
      </div>
      {props.isDisplay && (
        <div className="ms-auto">
          {/* <span className="blue font_18 me-3">{props?.rightContent?.displayContent}</span> */}
          {props?.feedbackBtnDisplay && (
            <span className="place-order-hover me-3">
              <button type="button" className="btn accept pe-4 ps-4" onClick={handleFeedbackClick}>
                {t('assignment.AddFeedbackButton')}
              </button>
            </span>
          )}
          {props?.cancelBtnDisplay && (
            <span className="place-order-hover me-3">
              <button type="button" className="btn accept pe-4 ps-4" onClick={handleCancelClick}>
                {t('assignment.cancelButton')}
              </button>
            </span>
          )}
          {favourite && (
            <span className="place-order-hover me-3">
              <button type="button" className="btn accept" onClick={handleFavClick}>
                {t('assignment.markFavButton')} <img src={start_blank} height={'22px'} />
              </button>
            </span>
          )}
          {props.buttonDisplay && (
            <span className="place-order-hover">
              <button type="button" className="btn accept pe-4 ps-4" onClick={handleEditClick}>
                {t('assignment.editButton')}
              </button>
            </span>
          )}
        </div>
      )}
      {props.addUnavailability && (
        <div className="ms-auto">
          <span className="place-order-hover">
            <button type="button" className="btn accept pe-4 ps-4" onClick={props.UnavailabilityOnClick}>
              {t('unAvailability.addButton')}
            </button>
          </span>
        </div>
      )}
    </div>
  );
};
