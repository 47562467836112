import React from 'react';
import { SubHeader } from '../../uiComponents';
import { useTranslation } from 'react-i18next';
import { FavouriteDataTableWrapper } from './FavouriteDataTable';

export const Favourite = (props) => {
  const { t } = useTranslation();
  const leftContent = {
    title: t('favourite.title'),
    breadcrumb: '',
  };
  return (
    <div className="bg_blue p-3 content">
      <div className="mt-2 pb-2">
        <SubHeader isDisplay={false} leftContent={leftContent} />
        <div className="bg-white p-3">
            <FavouriteDataTableWrapper id={props?.userId} t={t}/>
        </div>
      </div>
    </div>
  );
};
