import { useContext } from 'react';
import { PrivateRoute } from './ProtectedRoute';
import { Routes, Route } from 'react-router-dom';
import { AuthContext } from '../utils';
import {
  CustomerHome,
  AssignmentWrapper,
  InterpreterHome,
  ProfileWrapper,
  OrderWrapper,
  Favourite,
  DocumentList,
  LoginWrapper,
  RequestAssignmentWrapper,
  NotFound,
  ChangePasswordWrapper,
  ForgotPasswordWrapper,
  PublicationWrapper,
  UnAvailabilityWrapper
} from '../components';

export const AppRoutes = () => {
  const authContext = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<LoginWrapper />} />
      <Route path="/forgot-password" element={<ForgotPasswordWrapper />} />
      <Route path="assignment/:id" element={<RequestAssignmentWrapper />}  />
      <Route path="interpreter/dashboard" element={<PrivateRoute component={InterpreterHome} auth={authContext} />} />
      <Route path="customer/dashboard" element={<PrivateRoute component={CustomerHome} auth={authContext} />} />
      <Route path="order" element={<PrivateRoute component={OrderWrapper} auth={authContext} />} />
      <Route path="favourite" element={<PrivateRoute component={Favourite} auth={authContext} />} />
      <Route path="document" element={<PrivateRoute component={DocumentList} auth={authContext} />} />
      <Route path="view/:id" element={<PrivateRoute component={AssignmentWrapper} auth={authContext} />} />
      <Route path="profile" element={<PrivateRoute component={ProfileWrapper} auth={authContext} />} />
      <Route path="update-password" element={<PrivateRoute component={ChangePasswordWrapper} auth={authContext} noheader/>} />
      <Route path="publication" element={<PrivateRoute component={PublicationWrapper} auth={authContext} />} />
      <Route path="unavailable" element={<PrivateRoute component={UnAvailabilityWrapper} auth={authContext} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
