import { IReduxAction } from '../../interface';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  login: {},
  errorResponse: {},
  error: false,
  loading: false,
  profile: {},
  changePassword: {},
  forgotPassword: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    LOGIN_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    LOGIN_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        login: action.payload,
        loading: false,
        error: false,
      };
    },
    LOGIN_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    PROFILE_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    PROFILE_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        profile: action.payload,
        loading: false,
        error: false,
      };
    },
    PROFILE_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    CHANGE_PASSWORD_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    CHANGE_PASSWORD_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        changePassword: action.payload,
        loading: false,
        error: false,
      };
    },
    CHANGE_PASSWORD_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    CHANGE_PASSWORD_RESET: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        changePassword: {},
        errorResponse: {},
        loading: false,
      };
    },
    FORGOT_PASSWORD_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    FORGOT_PASSWORD_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        forgotPassword: action.payload,
        loading: false,
        error: false,
      };
    },
    FORGOT_PASSWORD_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    FORGOT_PASSWORD_RESET: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        forgotPassword: {},
        errorResponse: {},
        loading: false,
      };
    },
  },
});

export const {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  PROFILE_REQUEST,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_RESET,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS
} = userSlice.actions;
