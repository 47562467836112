import { IReduxAction } from '../../interface';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  response: {},
  error: false,
  errorResponse: {},
  loading: false,
  unavailableLoading: false,
  markResponse: {},
  removeResponse: {},
  feedbackLookup: {},
  addFeedbackResponse: {},
  documentList: {},
  unavailabilityList: {},
  unavailabilityRemove: {},
  unavailabilityAdd: {},
  unavailabilityLookup: {},
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    FAVOURITE_INTERPRETER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    FAVOURITE_INTERPRETER_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        response: action.payload,
        loading: false,
        error: false,
      };
    },
    FAVOURITE_INTERPRETER_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    MARK_FAVOURITE_INTERPRETER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    MARK_FAVOURITE_INTERPRETER_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        markResponse: action.payload,
        loading: false,
        error: false,
      };
    },
    MARK_FAVOURITE_INTERPRETER_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    REMOVE_FAVOURITE_INTERPRETER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    REMOVE_FAVOURITE_INTERPRETER_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        removeResponse: action.payload,
        loading: false,
        error: false,
      };
    },
    REMOVE_FAVOURITE_INTERPRETER_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    MARK_FAVOURITE_INTERPRETER_RESET: (state, action: IReduxAction) => {
      return {
        ...state,
        markResponse: {},
        loading: false,
        error: false,
      };
    },
    REMOVE_FAVOURITE_INTERPRETER_RESET: (state, action: IReduxAction) => {
      return {
        ...state,
        removeResponse: {},
        loading: false,
        error: false,
      };
    },
    FEEDBACK_LOOKUP_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    FEEDBACK_LOOKUP_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        feedbackLookup: action.payload,
        loading: false,
        error: false,
      };
    },
    FEEDBACK_LOOKUP_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    FEEDBACK_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    FEEDBACK_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        addFeedbackResponse: action.payload,
        loading: false,
        error: false,
      };
    },
    FEEDBACK_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    DOCUMENT_LIST_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    DOCUMENT_LIST_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        documentList: action.payload,
        loading: false,
        error: false,
      };
    },
    DOCUMENT_LIST_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    UNAVAILABILITY_LIST_REQUEST: (state) => {
      return {
        ...state,
        unavailableLoading: true,
        error: false,
      };
    },
    UNAVAILABILITY_LIST_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        unavailabilityList: action.payload,
        unavailableLoading: false,
        error: false,
      };
    },
    UNAVAILABILITY_LIST_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        unavailableLoading: false,
      };
    },
    UNAVAILABILITY_ADD_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    UNAVAILABILITY_ADD_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        unavailabilityAdd: action.payload,
        loading: false,
        error: false,
      };
    },
    UNAVAILABILITY_ADD_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    UNAVAILABILITY_REMOVE_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    UNAVAILABILITY_REMOVE_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        unavailabilityRemove: action.payload,
        loading: false,
        error: false,
      };
    },
    UNAVAILABILITY_REMOVE_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
    REMOVE_UNAVAILABILITY_REMOVE_RESET: (state, action: IReduxAction) => {
      return {
        ...state,
        unavailabilityRemove: {},
        loading: false,
        error: false,
      };
    },
    UNAVAILABILITY_LOOKUP_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    UNAVAILABILITY_LOOKUP_SUCCESS: (state, action: IReduxAction) => {
      return {
        ...state,
        unavailabilityLookup: action.payload,
        loading: false,
        error: false,
      };
    },
    UNAVAILABILITY_LOOKUP_ERROR: (state, action: IReduxAction) => {
      return {
        ...state,
        error: true,
        errorResponse: action.payload,
        loading: false,
      };
    },
  },
});

export const {
  FAVOURITE_INTERPRETER_ERROR,
  FAVOURITE_INTERPRETER_REQUEST,
  FAVOURITE_INTERPRETER_SUCCESS,
  MARK_FAVOURITE_INTERPRETER_ERROR,
  MARK_FAVOURITE_INTERPRETER_REQUEST,
  MARK_FAVOURITE_INTERPRETER_SUCCESS,
  MARK_FAVOURITE_INTERPRETER_RESET,
  REMOVE_FAVOURITE_INTERPRETER_ERROR,
  REMOVE_FAVOURITE_INTERPRETER_REQUEST,
  REMOVE_FAVOURITE_INTERPRETER_RESET,
  REMOVE_FAVOURITE_INTERPRETER_SUCCESS,
  FEEDBACK_ERROR,
  FEEDBACK_LOOKUP_ERROR,
  FEEDBACK_LOOKUP_REQUEST,
  FEEDBACK_LOOKUP_SUCCESS,
  FEEDBACK_REQUEST,
  FEEDBACK_SUCCESS,
  DOCUMENT_LIST_ERROR,
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_SUCCESS,
  UNAVAILABILITY_LIST_ERROR,
  UNAVAILABILITY_LIST_REQUEST,
  UNAVAILABILITY_LIST_SUCCESS,
  UNAVAILABILITY_REMOVE_ERROR,
  UNAVAILABILITY_REMOVE_REQUEST,
  UNAVAILABILITY_REMOVE_SUCCESS,
  REMOVE_UNAVAILABILITY_REMOVE_RESET,
  UNAVAILABILITY_LOOKUP_ERROR,
  UNAVAILABILITY_LOOKUP_REQUEST,
  UNAVAILABILITY_LOOKUP_SUCCESS,
  UNAVAILABILITY_ADD_ERROR,
  UNAVAILABILITY_ADD_REQUEST,
  UNAVAILABILITY_ADD_SUCCESS,
} = commonSlice.actions;
