import React from 'react';
import dateImage from '../../assets/images/calendar_1.svg';
import readmore from '../../assets/images/read_more.svg';
import { formatDate, DateFormats } from '../../utils';

const EmptyPublication = ({ t }) => {
  return (
    <div className="bg-white p-3">
      <p className="blue font_700 font_18 p-center">{t('publication.empty')}</p>
    </div>
  );
};

export const PublicationCard = ({ publication, t }) => {
  if (publication.length === 0) {
    return <EmptyPublication t={t} />;
  }
  return (
    <div className="row">
      {publication.map((value, index) => (
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12" key={index}>
          <div className="bg-white p-3">
            <p className="font_600 font_22 blue_light montserrat">{value.name}</p>
            <div>
              <img src={dateImage} alt="" />
              <span className="blue font_12 font_600 date-place">
                {formatDate(value.publishedFromDate, DateFormats.DATE)}
              </span>
            </div>
            <p className="gray mt-2"></p>
            <div dangerouslySetInnerHTML={{ __html: value.description }} />
            <p className="gray mt-2"></p>
            {value?.link !== '' && (
              <a href={value.link} className="place-order-hover-2" target={'_blank'}>
                <button type="submit" className="btn btn_read_more montserrat">
                  {t('publication.readMoreButton')} <img src={readmore} alt="" />
                </button>
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
