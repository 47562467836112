import React, { useEffect } from 'react';
import { userTypeEnums } from '../../interface';
import pdfIcon from '../../assets/images/pdf.svg';
import { formatDate } from '../../utils';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Loader } from '../Loader';
import { downloadAssignmentPDF, resetDownloadPdf } from '../../redux/action';
import { useAppDispatch } from '../../config/hook';
import './assignment.css';

const EmptyView = () => {
  return <p className="blue font_18">N/A</p>;
};

function downloadPDF(documentString, name) {
  const linkSource = documentString;
  const downloadLink = document.createElement('a');
  const fileName = name;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export const AssignmentView = ({ assignmentDetails, usertype, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onDownloadClick = (requestPayload) => {
    downloadAssignmentPDF(requestPayload, dispatch);
  };

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responsePayload = props?.response?.data;
      downloadPDF(responsePayload.documentString, responsePayload.name);
      resetDownloadPdf(dispatch);
    }
  }, [props.response?.data]);

  if (props.loading) return <Loader />;

  return (
    <div className="bg-white">
      <div className="row">
        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <div className="pt-5 pb-5 ps-4">
            <div className="assignment_info">
              <p className="light_blue">{t('dashboard.table.assignmentNo')}</p>
              <p className="blue font_18">{assignmentDetails?.assignmentNo}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.customerNo')}</p>
              <p className="blue font_18">{assignmentDetails?.customerNo}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.orderByContactName')}</p>
              <p className="blue font_18">{assignmentDetails?.orderByContactName}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.language')}</p>
              <p className="blue font_18">{assignmentDetails?.language}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.duration')}</p>
              {assignmentDetails?.duration}
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.skillLevel')}</p>
              {assignmentDetails?.skillLevel}
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.additionalInfo')}</p>
              {assignmentDetails?.additionalInfo}
            </div>
          </div>
        </div>
        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <div className="pt-5 pb-5 ps-4">
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.assignmentType')}</p>
              <p className="blue font_18">{assignmentDetails?.assignmentType}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.referenceNo')}</p>
              <p className="blue font_18">{assignmentDetails?.referenceNo}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.additionalContact')}</p>
              {assignmentDetails?.additionalContact}
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.startTime')}</p>
              <p className="blue font_18">
                {assignmentDetails?.startTime ? formatDate(assignmentDetails?.startTime) : ''}
              </p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.address')}</p>
              <p className="blue font_18">{assignmentDetails?.onSiteAddress}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.impartialityEvalRequired')}</p>
              <p className="blue font_18">{assignmentDetails?.impartialityEval ? 'Yes' : 'No'}</p>
            </div>
            <div className="assignment_info">
              <p className="light_blue">{t('assignment.uploadedDocuments')}</p>
              {usertype === userTypeEnums.CUSTOMER && assignmentDetails?.orderConfirmationUrlCustomer?.length > 1 && (
                <div
                  className="download-pdf"
                  onClick={() => {
                    onDownloadClick(assignmentDetails?.orderConfirmationUrlCustomer);
                  }}>
                  <span>
                    <img src={pdfIcon} alt="" />
                  </span>
                </div>
              )}
              {usertype === userTypeEnums.INTERPRETER &&
                assignmentDetails?.orderConfirmationUrlInterpreter?.length > 1 && (
                  <div
                    className="download-pdf"
                    onClick={() => {
                      onDownloadClick(assignmentDetails?.orderConfirmationUrlInterpreter);
                    }}>
                    <span>
                      <img src={pdfIcon} alt="" />
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { assignment } = state;
  return {
    loading: assignment.loading,
    response: assignment.downloadAssignment,
    error: assignment.error,
    errorResponse: assignment.errorResponse,
  };
}

export const AssignmentViewWrapper = connect(mapStateToProps)(AssignmentView);
